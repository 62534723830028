@charset "UTF-8";

// ベーステキストカラー
$c-base: #383a3c;
// 説明文などのサブテキストカラー
$c-subbase: #64686c;
// 日付などのカラー
$c-sub: #868c91;
// アクセントカラー
$c-accent: #5ea3e2;
$c-notes: #e4465b;
// 問い合わせホバー時アクセントカラー
$c-inquery-accent: #7eb5e8;
// 主にバックグランドで使用
$c-bg: #effaff;
$c-bgfooter: #21445b;