@charset "UTF-8";
@use "./color" as *;
@use "./mixin" as *;
@use "./font" as *;

/**
* 全ページトランザクション
*****************************************************/

// opacity
$opacity-035: 0.35;

// 0.2sトランディション
$hover-transition_200: 200ms;

// 0.3sトランディション
$hover-transition_300: 300ms;

// 0.5sトランディション
$img-hover-transition: 500ms;

// 画像ホバー時スケール1.2
$hover-scale_12: scale(1.2);

// 画像ホバー時スケール1.2
$hover-scale_11: scale(1.1);

// ボックスシャドウ
$box-shadow: 0px 5px 20px 0px rgba(2 37 76 / 0.07);

// 行間
// 2.0 この行間は、通常の本文テキストで使用します
$letter-space20: 2px;

// 1.6 この行間は、リード、注釈等で使用します
$letter-space16: 1.6px;

// 1.3 この行間は、見出し、キャッチコピー、アイコンテキスト等で使用します
$letter-space13: 1.3px;


// ページ遷移矢印アイコン
.c-toppage_arrow {
  width: 15px;
}

// 左側の流体
.fluid-img_type1 {
  display: block;
  width: 640px;
  height: 706px;
  position: absolute;
  left: -60px;
  z-index: -1;
  overflow: hidden;
  @include sp {
    width: 200px;
    height: 175px;
    left: 0;
  }
  &_img {
    width: 800px;
    object-fit: contain;
    object-position: -160px;
    @include sp {
      width: 200px;
      object-position: -50px;
    }
  }
}

// 右側の流体
.fluid-img_type2 {
  display: block;
  width: 640px;
  height: 706px;
  position: absolute;
  right: 0;
  z-index: -1;
  overflow: hidden;
  @include sp {
    width: 140px;
    height: 175px;
  }
  &_img {
    width: 800px;
    object-fit: contain;
    @include sp {
      width: 200px;
    }
  }
}
