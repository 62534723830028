@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

/**
* ボタンコンポーネント
*****************************************************/

// ViewMoreボタン
.c-more {
  display: flex;
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
  &_link {
    display: flex;
    align-items: center;
    &:hover {
      .c-more_link_img {
        transform: $hover-scale_11;
        transition: $hover-transition_300;
        &_arrow {
          transform: scale(0.9);
          transition: $hover-transition_300;
          &.deg45 {
            transform: scale(0.9) rotate(-45deg);
          }
        }
      }
    }
    &_txt {
      font-size: 1.6rem;
      font-family: $over-pass;
      @include sp {
        font-size: 1.5rem;
      }
    }
    &_img {
      width: 60px;
      height: 60px;
      margin-left: 20px;
      border-radius: 50%;
      border: 2px solid $c-accent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $hover-transition_300;
      @include sp {
        width: 52px;
        height: 52px;
      }
      &_arrow {
        width: 15px;
        height: 14px;
        transition: $hover-transition_300;
        &.deg45 {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

// 戻るボタン
.c-back_button {
  min-width: 240px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  @include sp {
    min-height: 50px;
  }
  &_link {
    min-width: 240px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &_border {
      min-width: 240px;
      width: 100%;
      height: 100%;
      border: 2px solid $c-accent;
      border-radius: 30px;
      transition: $hover-transition_300;
    }
    &_txt {
      width: fit-content;
      color: $c-accent;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: $letter-space13;
      transition: $hover-transition_300;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include sp {
        font-size: 1.5rem;
      }
    }
    &:hover {
 
      .c-back_button_link_border {
        transform: $hover-scale_11;
        transition: $hover-transition_300;
      }
    }
  }
}