@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.sns {
  margin-top: 60px;
  @include sp {
    margin-top: 30px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_snslist {
      &_list {
        padding-left: 5px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &_item {
          &:not(:first-child) {
            margin-left: 30px;
            @include sp {
              margin-left: 25px;
            }
          }
          @include sp {
            width: calc(100% / 3 - 30px);
            margin: 12.5px;
            flex-shrink: 0;
            &:nth-child(3n+1) {
              margin: 12.5px 12.5px 12.5px 0;
            }
          }
          &_link {
            font-size: 1.6rem;
            font-family: $over-pass;
            @include sp {
              display: flex;
              align-items: center;
            }
            &:hover {
              transition: $hover-transition_200;
              color: $c-accent;
            }
            &::before {
              content: '';
              width: 7px;
              height: 12px;
              margin-right: 10px;
              display: inline-block;
              background: url(/assets/img/common/img_common_arrow_bottom.svg);
              background-size: cover;
              transform: rotate(90deg);
              @include sp {
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
    &_content {
      .sns-contents {
        // margin-top: 100px;
        margin-top: -60px;
        padding-top: 160px;
        @include sp {
          padding-top: 90px;
          margin-top: -30px;
        }
        &_inner {
          .c-h3_ttl {
            &_img {
              width: 48px;
              @include sp {
                width: 36px;
              }
            }
            &_txt {
              margin-left: 15px;
              padding-top: 5px;
            }
          }
          &_list {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            &_item {
              width: calc(100% / 2 - 20px);
              @include sp {
                width: 100%;
              }
              &:nth-child(even) {
                margin-left: 40px;
                @include sp {
                  margin-left: 0;
                }
              }
              &:nth-child(n+3) {
                margin-top: 60px;
                @include sp {
                  margin-top: 0;
                }
              }
              &:not(:first-child) {
                @include sp {
                  margin-top: 30px;
                }
              }
              &_img {
                overflow: hidden;
                border-radius: 18px;
                border: 1px solid #d4d6d9;
              }
              &_ttl {
                margin-top: 20px;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 3rem;
                letter-spacing: $letter-space16;
                @include sp {
                  margin-top: 15px;
                  font-size: 1.6rem;
                }
              }
              &_txt {
                margin-top: 10px;
                font-size: 1.4rem;
                line-height: 3rem;
                letter-spacing: $letter-space20;
                color: #64686c;
                @include sp {
                  margin-top: 5px;
                  font-size: 1.3rem;
                }
              }
              &_view-more {
                margin-top: 40px;
                @include sp {
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
    }
  }
}