@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.not-found {
  margin-top: 80px;
  @include sp {
    margin-top: 30px;
  }
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_ttl {
      display: flex;
      justify-content: center;
      color: $c-accent;
      &_txt {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: $letter-space13;
        @include sp {
          font-size: 2.2rem;
        }
      }
    }
    &_content {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include sp {
        margin-top: 20px;
      }
      &_txt {
        font-size: 1.6rem;
        text-align: center;
        letter-spacing: $letter-space20;
        line-height: 3.5rem;
        @include sp {
          font-size: 1.5rem;
          text-align: left;
        }
      }
      &_notes {
        margin-top: 30px;
        text-align: center;
        @include sp {
          margin-top: 20px;
        }
      }
    }
    &_button {
      margin-top: 70px;
      @include sp {
        margin-top: 60px;
      }
    }
  }
}