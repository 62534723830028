@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

// 問合わせページ
.inquiry {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_notes {
      width: 100%;
      padding: 45px 40px;
      border: 1px solid #e9eaec;
      border-radius: 20px;
      @include sp {
        padding: 40px 20px;
      }
      &_list {
        &_item {
          font-size: 1.6rem;
          line-height: 3rem;
          letter-spacing: $letter-space16;
          @include sp {
            font-size: 1.5rem;
            line-height: 2.8rem;
          }
          &:not(:first-child) {
            margin-top: 20px;
          }
          &_txt {
            display: flex;
            align-items: flex-start;
            &::before {
              content: '';
              width: 6px;
              height: 6px;
              margin-top: 12px;
              margin-right: 10px;
              background: $c-accent;
              border-radius: 50%;
              display: block;
              flex-shrink: 0;
              @include sp {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    & .mw_wp_form_input {
      & .inquiry-confirm_inner_content_form_list {
        display: none;
      }
    }
    &_form {
      margin-top: 50px;
      @include sp {
        margin-top: 40px;
      }
      &_list {
        &_item {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          @include sp {
            flex-direction: column;
            align-items: flex-start;
            justify-content: inherit;
          }
          &:not(:first-child) {
            margin-top: 30px;
          }
          .form-reference_select {
            width: calc(85% - 15px);
            position: relative;
            @include sp {
              width: 100%;
              margin-top: 20px;
            }
            &_list {
              width: 332px;
              padding: 20px;
              border: 1px solid #e9eaec;
              border-radius: 8px;
              font-size: 1.6rem;
              cursor: pointer;
              &:-webkit-autofill {
                box-shadow: 0 0 0 1000px white inset;
              }
              &.is-error {
                background: #fef6f6;
                outline: 1px solid $c-notes;
                border: 1px solid $c-notes;
              }
              @include sp {
                width: 100%;
              }
              &.wpcf7-form-control {
                &.wpcf7-not-valid {
                  background: #fef6f6;
                  outline: 1px solid $c-notes;
                  border: 1px solid $c-notes;
                }
                &:focus {
                  outline: 1px solid $c-accent;
                  border: 1px solid $c-accent;
                }
              }
              &:focus {
                outline: 1px solid $c-accent;
                border: 1px solid $c-accent;
              }
            }
            &_img {
              width: 7px;
              position: absolute;
              top: 24px;
              left: 302px;
              transform: rotate(90deg);
              @include sp {
                left: inherit;
                right: 20px;
              }
            }
            .validation_txt {
              margin-top: 15px;
              font-size: 1.4rem;
              font-weight: bold;
              color: $c-notes;
              display: none;
              &.is-error {
                display: block;
              }
            }
            .wpcf7-form-control-wrap {
              .wpcf7-not-valid-tip {
                margin-top: 15px;
                font-size: 1.4rem;
                font-weight: bold;
                color: $c-notes;
              }
            }
            .error {
              margin-top: 15px;
              font-size: 1.4rem !important;
              font-weight: bold;
              color: $c-notes !important;
              display: block;
            }
          }
          .form-ttl {
            width: calc(15% - 15px);
            padding-top: 22px;
            font-size: 1.6rem;
            font-weight: bold;
            letter-spacing: $letter-space13;
            pointer-events: none;
            @include sp {
              padding-top: 0;
            }
            &.content {
              padding-top: 100px;
              @include sp {
                padding-top: 0;
              }
            }
            @include sp {
              width: auto;
            }
          }
          &_wrap {
            width: calc(85% - 15px);
            @include sp {
              width: 100%;
            }
            .form-input {
              width: 100%;
              padding: 20px;
              border: 1px solid #e9eaec;
              border-radius: 8px;
              font-size: 1.6rem;
              &:-webkit-autofill {
                box-shadow: 0 0 0 1000px white inset;
              }
              &.is-error {
                background: #fef6f6;
                outline: 1px solid $c-notes;
                border: 1px solid $c-notes;
              }
              @include sp {
                width: 100%;
                margin-top: 20px;
                font-size: 1.5rem;
              }
              &.wpcf7-form-control {
                &.wpcf7-not-valid {
                  background: #fef6f6;
                  outline: 1px solid $c-notes;
                  border: 1px solid $c-notes;
                }
                &:focus {
                  outline: 1px solid $c-accent;
                  border: 1px solid $c-accent;
                }
              }
              &:focus {
                outline: 1px solid $c-accent;
                border: 1px solid $c-accent;
              }
            }
            .error {
              margin-top: 15px;
              font-size: 1.4rem !important;
              font-weight: bold;
              color: $c-notes !important;
              display: block;
            }
            .form-txtarea {
              width: 100%;
              min-height: 160px;
              padding: 20px;
              border: 1px solid #e9eaec;
              border-radius: 8px;
              font-size: 1.6rem;
              resize: none;
              &:-webkit-autofill {
                box-shadow: 0 0 0 1000px white inset;
              }
              &.is-error {
                background: #fef6f6;
                outline: 1px solid $c-notes;
                border: 1px solid $c-notes;
              }
              @include sp {
                width: 100%;
                margin-top: 20px;
                font-size: 1.5rem;
              }
              &.wpcf7-form-control {
                &.wpcf7-not-valid {
                  background: #fef6f6;
                  outline: 1px solid $c-notes;
                  border: 1px solid $c-notes;
                }
                &:focus {
                  outline: 1px solid $c-accent;
                  border: 1px solid $c-accent;
                }
              }

              &:focus {
                outline: 1px solid $c-accent;
                border: 1px solid $c-accent;
              }
            }
            .error {
              margin-top: 15px;
              font-size: 1.4rem !important;
              font-weight: bold;
              color: $c-notes !important;
              display: block;
            }
            .validation_txt {
              margin-top: 15px;
              font-size: 1.4rem;
              font-weight: bold;
              color: $c-notes;
              display: none;
              &.is-error {
                display: block;
              }
            }
            .wpcf7-form-control-wrap {
              .wpcf7-not-valid-tip {
                margin-top: 15px;
                font-size: 1.4rem;
                font-weight: bold;
                color: $c-notes;
              }
            }
          }
        }
      }
      &_confirm {
        width: 240px;
        height: 60px;
        margin: 70px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &_border {
          width: 100%;
          height: 100%;
          background: $c-accent;
          border-radius: 30px;
        }
        @include sp {
          width: 240px;
          height: 50px;
          margin: 40px auto 0;
        }
        cursor: pointer;
        &:hover {
          .inquiry_inner_form_confirm_border {
            transform: $hover-scale_11;
            transition: $hover-transition_300;
          }
        }
        &_submit {
          width: 100%;
          height: 100%;
          text-align: center;
          color: #fff;
          font-size: 1.6rem;
          font-weight: bold;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// 確認画面
.inquiry-confirm {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_ttl {
      &_txt {
        text-align: center;
      }
    }
    & .mw_wp_form_preview {
      & .inquiry_inner_form {
        &_list {
          &_item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 0;
            @include sp {
              margin-top: 0;
              flex-direction: row;
            }
            .form-ttl {
              width: calc(20% - 15px);
              padding: 25px 0 35px;
              border-top: 1px solid $c-accent;
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              font-weight: bold;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
              @include sp {
                width: calc(30% - 10px);
                padding: 20px 0 30px;
              }
              &_txt {
                font-size: 1.6rem;
                letter-spacing: $letter-space16;
                font-weight: bold;
                line-height: 2.5rem;
                @include sp {
                  font-size: 1.5rem;
                }
              }
            }
            .form-reference_select,
            .inquiry_inner_form_list_item_wrap {
              width: calc(80% - 15px);
              margin-left: 30px;
              padding: 25px 0 35px;
              border-top: 1px solid #d4d6d9;
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              line-height: 2.5rem;
              word-break: break-all;
              .form-reference_select_img {
                display: none;
              }
              @include sp {
                width: calc(70% - 10px);
                margin-top: 0;
                margin-left: 15px;
                padding: 20px 0 30px;
                font-size: 1.5rem;
              }
            }
          }
        }
        & .form-reference_select_img {
          display: none;
        }
      }
      .inquiry_inner_form_confirm {
        display: none;
      }
    }
    &_content {
      margin-top: 60px;
      @include sp {
        margin-top: 30px;
      }
      &_list {
        &_item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .inquiry-confirm_ttl {
            width: calc(20% - 15px);
            padding: 25px 0 35px;
            border-top: 1px solid $c-accent;
            @include sp {
              width: calc(30% - 10px);
              padding: 20px 0 30px;
            }
            &_txt {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              font-weight: bold;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
            }
          }
          .inquiry-confirm_detail {
            width: calc(80% - 15px);
            margin-left: 30px;
            padding: 25px 0 35px;
            border-top: 1px solid #d4d6d9;
            @include sp {
              width: calc(70% - 10px);
              margin-left: 15px;
              padding: 20px 0 30px;
            }
            &_ttl {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              font-weight: bold;
              @include sp {
                font-size: 1.5rem;
              }
            }
            &_txt {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
      &_form {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        &_list {
          display: flex;
          justify-content: center;
          align-items: center;
          @include sp {
            flex-direction: column-reverse;
          }
          &_item {
            width: 240px;
            height: 60px;
            position: relative;
            &_border {
              width: 100%;
              height: 100%;
              border-radius: 30px;
              border: 2px solid $c-accent;
            }
            &:hover {
              .inquiry-confirm_inner_content_form_list_item_border {
                transform: $hover-scale_11;
                transition: $hover-transition_300;
              }
            }
            &_link {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              border-radius: 30px;
              color: $c-accent;
              font-size: 1.6rem;
              font-weight: bold;
              position: absolute;
              top: 0;
              left: 0;
            }
            &_btn {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              border-radius: 30px;
              color: #fff;
              font-size: 1.6rem;
              font-weight: bold;
              position: absolute;
              top: 0;
              left: 0;
            }
            &.go-back {
              margin-right: 10px;
              @include sp {
                margin-right: 0;
                margin-top: 20px;
              }
            }
            &.submit {
              margin-left: 10px;
              .inquiry-confirm_inner_content_form_list_item_border {
                background: $c-accent;
              }
              @include sp {
                margin-: 0;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

// 完了画面
.inquiry-complete {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_ttl {
      &_txt {
        font-size: 3rem;
        font-weight: bold;
        color: $c-accent;
        letter-spacing: $letter-space16;
        text-align: center;
        @include sp {
          font-size: 2.2rem;
          line-height: 3.5rem;
        }
      }
    }
    &_comptxt {
      margin-top: 30px;
      @include sp {
        margin-top: 20px;
      }
      &_txt {
        text-align: center;
        @include sp {
          text-align: left;
        }
      }
    }
    &_button {
      margin-top: 70px;
      @include sp {
        margin-top: 50px;
      }
    }
  }
}