@charset "UTF-8";
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/mixin" as *;
.header {
  width: 100%;
  margin-top: 30px;
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  @include sp {
    background: #fff;
    position: fixed;
  }
  &.sp-active {
    background: #fff;
    position: fixed;
  }
  @include sp {
    height: 60px;
    margin-top: 0;
    padding: 12px 20px;
  }
  &_inner {
    padding: 20px 50px;
    background: #fff;
    border-radius: 50px;
    box-shadow: $box-shadow;
    @include pc-md {
      padding: 20px 25px;
    }
    &_contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_logo {
        width: 219px;
        @include pc-md {
          width: 181px;
        }
        @include pc-smd {
          width: 130px;
        }
      }
      &_gnavi {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-left: 20px;
        &_list {
          height: 50px;
          display: flex;
          align-items: center;
          &_item {
            position: relative;
            &:not(:first-child) {
              margin-left: 30px;
              @include pc-md {
                margin-left: 20px;
              }
              @include pc-smd {
                margin-left: 15px;
              }
            }
            &_link {
              display: flex;
              align-items: center;
              &:hover {
                .header_inner_contents_gnavi_list_item_link_txt {
                  transition: $hover-transition_200;
                  color: $c-accent;
                }
              }
              &_txt {
                font-size: 1.6rem;
                font-weight: bold;
                letter-spacing: $letter-space13;
                @include pc-md {
                  font-size: 1.5rem;
                }
                @include pc-smd {
                  font-size: 1.2rem;
                }
                @include pc-sm {
                  font-size: 1.15rem;
                }
              }
              &_img {
                width: 8px;
                margin-left: 10px;
                transform: rotate(90deg);
              }
              &_arrowimg {
                width: 14px;
                margin-left: 8px;
                transform: rotate(-45deg);
              }
            }
            .gnavi_lower {
              width: 200px;
              padding: 30px;
              display: none;
              position: absolute;
              top: 70px;
              left: -40%;
              background: #fff;
              border-radius: 32px;
              // opacity: 0;
              // visibility: hidden;
              transition: 0.2s 0.1s;
              &.active {
                opacity: 1;
                visibility: visible;
                transition: 0.2s;
              }
              &_ttl {
                &_link {
                  display: flex;
                  align-items: center;
                  &:hover {
                    .gnavi_lower_ttl_link_txt {
                      transition: $hover-transition_200;
                      color: $c-accent;
                    }
                  }
                  &_txt {
                    font-size: 1.8rem;
                    font-weight: bold;
                    letter-spacing: $letter-space13;
                    @include pc-md {
                      font-size: 1.6rem;
                    }
                  }
                  &_img {
                    width: 15px;
                    margin-left: 10px;
                  }
                }
              }
              &_list {
                margin-top: 30px;
                &_item {
                  &_link {
                    font-size: 1.6rem;
                    letter-spacing: $letter-space13;
                    &:hover {
                      transition: $hover-transition_200;
                      color: $c-accent;
                    }
                    @include pc-md {
                      font-size: 1.5rem;
                    }
                    &.ablank {
                      display: flex;
                      align-items: center;
                      .gnavi_lower_list_item_link_img {
                        width: 15px;
                        margin-left: 8px;
                        @include sp {
                          width: 13px;
                        }
                        &.deg45 {
                          transform: rotate(-45deg);
                        }
                      }
                    }
                  }
                  &:not(:first-child) {
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
        &_inquery {
          width: 130px;
          height: 50px;
          margin-left: 30px;

          @include pc-md {
            width: 110px;
            height: 50px;
            margin-left: 20px;
          }
          @include pc-smd {
            width: 90px;
            margin-left: 15px;
          }
          &:hover {
            .header_inner_contents_gnavi_inquery_link_border {
              transform: $hover-scale_11;
              transition: $hover-transition_200;
            }
          }
          &_link {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &_border {
              width: 100%;
              height: 100%;
              border-radius: 30px;
              background: $c-accent;
            }
            &_txt {
              color: #fff;
              font-size: 1.6rem;
              font-weight: bold;
              position: absolute;
              white-space: nowrap;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include pc-md {
                font-size: 1.5rem;
              }
              @include pc-smd {
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
    .over-ray {
      width: 100%;
      height: 110vh;
      position: absolute;
      top: -30px;
      left: 0;
      background: #000;
      // opacity: 0;
      // visibility: hidden;
      display: none;
      opacity: 0.3;
      z-index: -1;
      transition: all 0.3s 0.2s;
      &.active {
        opacity: 0.3;
        visibility: visible;
        transition: all 0.3s;
      }
    }
  }
  // SPのstyle
  @include sp {
    .header-sp_inner {
      &_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_logo {
          width: 199px;
        }
        &_drawer {
          &_box {
            width: 24px;
            height: 18px;
            position: relative;
            
            span {
              display: block;
              width: 100%;
              height: 2px;
              background: $c-accent;
              position: absolute;
              left: 0;
              transition: 0.3s;
              &:nth-of-type(1) {
                top: 0;
              }
              &:nth-of-type(2) {
                top: 8px;
                opacity: 1;
              }
              &:nth-of-type(3) {
                bottom: 0;
              }
            }
            &.active {
              span {
                transition: 0.3s;
                &:nth-of-type(1) {
                  top: 8px;
                  transform: rotate(45deg);
                }
                &:nth-of-type(2) {
                  opacity: 0;
                }
                &:nth-of-type(3) {
                  bottom: 8px;
                  transform: rotate(-45deg);
                }
              }
            }
          }
          &_nav {
            width: 100%;
            height: calc(100vh - 60px);
            padding: 0 30px 50px;
            display: none;
            background: #fff;
            position: fixed;
            top: 60px;
            left: 0;
            overflow: scroll;
            &_list {
              &_item {
                padding: 20px 0;
                border-bottom: 1px solid #ced7e0;
                &_link {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  &_txt {
                    font-size: 1.5rem;
                    font-weight: bold;
                  }
                  &_img {
                    width: 15px;
                    &.deg45 {
                      transform: rotate(-45deg);
                    }
                  }
                }
                .gnavi_lower_list {
                  padding: 20px 20px 0;
                  &_item {
                    &:not(:first-child) {
                      margin-top: 15px;
                    }
                    &_link {
                      display: block;
                      font-size: 1.4rem;
                      &.ablank {
                        display: flex;
                        align-items: center;
                        .gnavi_lower_list_item_link_img {
                          width: 15px;
                          margin-left: 8px;
                          @include sp {
                            width: 13px;
                          }
                          &.deg45 {
                            transform: rotate(-45deg);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &_sublist {
              margin-top: 30px;
              &_item {
                &:not(:first-child) {
                  margin-top: 15px;
                }
                &_link {
                  font-size: 1.2rem;
                }
              }
            }
            &_inquery {
              margin-top: 30px;
              &_link {
                width: 130px;
                height: 48px;
                color: #fff;
                font-size: 1.4rem;
                font-weight: bold;
                background: $c-accent;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}