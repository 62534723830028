@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

// リクルートトップ
.recruit-mv {
  height: 80vh;
  padding-left: 40px;
  position: relative;
  @include sp {
    height: inherit;
    padding-left: 20px;
  }
  &_ttl {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    @include sp {
      margin-top: 100px;
      padding: 0;
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
  &_mvimg {
    width: calc(100% - 40px);
    max-width: 88%;
    height: 80vh;
    padding-left: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include sp {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
      position: inherit;
      max-width: inherit;
      height: inherit;
    }
    img {
      width: inherit;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 60px;
      object-fit: cover;
      @include sp {
        height: auto;
        border-radius: 20px 0 0 20px;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }
}

.recruit-content {
  margin-top: 70px;
  @include sp {
    margin-top: 50px;
  }
  &_recruting {
    &_inner {
      width: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp {
        padding: 0 20px;
        flex-direction: column-reverse;
      }
      &_detail {
        width: 50%;
        padding-right: 50px;
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-right: 0;
        }
        &_view-more {
          margin-top: 40px;
          @include sp {
            margin-top: 25px;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        padding-left: 20px;
        @include sp {
          width: 100%;
          padding-left: 0;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
  &_career {
    margin-top: 70px;
    @include sp {
      margin-top: 40px;
    }
    &_inner {
      width: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      @include sp {
        flex-direction: column-reverse;
      }
      @include sp {
        padding: 0 20px;
      }
      &_detail {
        width: 50%;
        padding-left: 50px;
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-left: 0;
        }
        &_view-more {
          margin-top: 40px;
          @include sp {
            margin-top: 25px;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        padding-right: 20px;
        @include sp {
          width: 100%;
          padding-right: 0;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
  &_member {
    margin-top: 100px;
    background: $c-bg;
    @include sp {
      margin-top: 70px;
    }
    &_inner {
      padding: 100px 0 120px;
      @include sp {
        padding: 60px 0 70px;
      }
      &_ttl {
        padding: 0 40px;
        @include sp {
          padding: 0 20px;
        }
      }
      .recruit-content_slider {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
        &_list {
          margin: 0 auto;
          &_item {
            width: 370px !important;
            position: relative;
            @include sp {
              width: 295px !important;
            }
            &_link {
              &:hover {
                .member-item {
                  &_img {
                    img {
                      transform: $hover-scale_12;
                      transition: $img-hover-transition;
                    }
                  }
                }
              }
              .member-item {
                &_img {
                  position: relative;
                  z-index: 1;
                  overflow: hidden;
                  border-radius: 20px;
                }
                &_layer {
                  width: 100%;
                  height: 166px;
                  background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7));
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  z-index: 2;
                  display: flex;
                  align-items: flex-end;
                  overflow: hidden;
                  border-radius: 0 0 20px 20px;
                  &_inner {
                    padding: 40px 45px;
                    &_txt {
                      font-size: 2.4rem;
                      color: #fff;
                      font-weight: bold;
                      letter-spacing: $letter-space13;
                    }
                    &_arrow {
                      width: 15px;
                      position: absolute;
                      bottom: 20px;
                      right: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .swiper-button-prev {
          width: 60px;
          height: 60px;
          background: #fff;
          border-radius: 50%;
          left: 40px;
          @include sp {
            width: 48px;
            height: 48px;
            left: 10px;
          }
          &::after {
            content: inherit;
          }
          &:hover {
            transform: $hover-scale_11;
            transition: $hover-transition_300;
            img {
              transform: rotate(180deg) scale(0.9);
              transition: $hover-transition_300;
            }
          }
          img {
            width: 7px;
            transform: rotate(180deg);
          }
        }
        .swiper-button-next {
          width: 60px;
          height: 60px;
          background: #fff;
          border-radius: 50%;
          right: 40px;
          @include sp {
            width: 48px;
            height: 48px;
            right: 10px;
          }
          &::after {
            content: inherit;
          }
          &:hover {
            transform: $hover-scale_11;
            transition: $hover-transition_300;
            img {
              transform: scale(0.9);
              transition: $hover-transition_300;
            }
          }
          img {
            width: 7px;
          }
        }
      }
    }
  }
  &_office {
    margin-top: 100px;
    @include sp {
      margin-top: 70px;
    }
    &_inner {
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &_img {
        margin-top: 40px;
        @include sp {
          margin-top: 30px;
        }
      }
      &_view-more {
        margin-top: 50px;
        justify-content: center;
        @include sp {
          margin-top: 25px;
          justify-content: flex-end;
        }
      }
    }
  }
}

// 社員紹介
.recruit-employee {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_intro {
      width: 100%;
      &_img {
        width: 100%;
        position: relative;
        z-index: 1;
        img {
          border-radius: 20px;
        }
      }
      &_details {
        width: calc(100% - 120px);
        margin: 0 auto;
        padding: 40px;
        background: #fff;
        border-radius: 20px;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -70px;
        z-index: 2;
        @include sp {
          width: calc(100% - 40px);
          padding: 30px 20px;
          top: -50px;
        }
        &_name {
          font-size: 3rem;
          font-weight: bold;
          letter-spacing: $letter-space13;
          @include sp {
            font-size: 2.2rem;
          }
        }
        &_en-name {
          margin-top: 20px;
          font-size: 1.7rem;
          color: $c-accent;
          letter-spacing: $letter-space13;
          font-family: $over-pass;
          @include sp {
            margin-top: 15px;
            font-size: 1.5rem;
          }
        }
        &_list {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          @include sp {
            margin-top: 5px;
            flex-direction: column;
          }
          &_item {
            margin: 20px 10px 0;
            font-size: 1.6rem;
            letter-spacing: $letter-space13;
            @include sp {
              margin: 10px 10px 0;
              font-size: 1.5rem;
            }
            .intro-ttl {
              font-weight: bold;
            }
          }
        }
      }
    }
    &_content {
      .employee-question {
        &:not(:first-child) {
          margin-top: 80px;
          @include sp {
            margin-top: 50px;
          }
        }
        &_ttl {
          &_num {
            font-size: 1.7rem;
            color: $c-accent;
            font-family: $over-pass;
            @include sp {
              font-size: 1.5rem;
            }
          }
          &_txt {
            margin-top: 15px;
            padding-left: 10px;
            font-size: 2.4rem;
            font-weight: bold;
            letter-spacing: $letter-space13;
            border-left: 3px solid $c-accent;
            @include sp {
              margin-top: 10px;
              font-size: 1.8rem;
              line-height: 2.5rem;
            }
          }
        }
        &_reply {
          margin-top: 25px;
          @include sp {
            margin-top: 20px;
          }
          &_txt {
            @include nomal-txt
          }
        }
      }
    }
  }
}


// 中途採用
.recruit-career {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_sec {
      &:not(:first-child) {
        margin-top: 100px;
        @include sp {
          margin-top: 40px;
        }
      }
      &_content {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include sp {
          flex-direction: column-reverse;
        }
        &_detail {
          width: calc(100% / 2 - 40px);
          @include sp {
            width: 100%;
            margin-top: 30px;
          }
          &_view-more {
            margin-top: 40px;
            justify-content: flex-start;
            @include sp {
              margin-top: 30px;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
        &_img {
          width: calc(100% / 2 - 40px);
          overflow: hidden;
          border-radius: 18px;
          @include sp {
            width: 100%;
          }
        }
      }
    }
  }
}

// オフィス
.recruit-office {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    &_top-content {
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &_list {
        &_item {
          cursor: pointer;
          &:hover {
            .recruit-office-display-contents_img {
              img {
                transform: $hover-scale_12;
                transition: $img-hover-transition;
              }
            }
            .recruit-office-display-contents_ttl {
              color: $c-accent;
              transition: $hover-transition_300;
            }
          }
          .js-modal-contents {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100000;
            &_wrap {
              max-width: 800px;
              width: 80%;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 100001;
              transform: translate(-50%,-50%);
              @include sp {
                width: 100%;
                padding: 0 20px;
              }
              &_back {
                display: flex;
                justify-content: flex-end;
                &_box {
                  width: 35px;
                  height: 35px;
                  display: block;
                  position: relative;
                  cursor: pointer;
                  &:hover {
                    transform: $hover-scale_11;
                    transition: $hover-transition_300;
                  }
                  span {
                    width: 100%;
                    height: 2px;
                    display: block;
                    background: #fff;
                    position: absolute;
                    left: 0;
                    &:nth-of-type(1) {
                      top: 5px;
                      transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                      top: 5px;
                      transform: rotate(-45deg);
                    }
                  }
                }
              }
              &_img {
                cursor: auto;
                width: 100%;
              }
            }
            .office-bg {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100000;
              background: #000;
              opacity: 0.5;
              cursor: pointer;
            }
          }
        }
      }
    }
    &_other-content {
      margin-top: 260px;
      padding-bottom: 80px;
      background: $c-bg;
      @include sp {
        margin-top: 160px;
        padding-bottom: 60px;
      }
      &_wrap {
        padding: 0 40px;
        position: relative;
        bottom: 160px;
        margin-bottom: -160px;
        @include sp {
          padding: 0 20px;
          bottom: 80px;
          margin-bottom: -80px;
        }
        &_top {
          &_imgtxt {
            display: flex;
            justify-content: space-between;
            @include sp {
              flex-direction: column;

            }
            &_img {
              width: calc(50% - 20px);
              overflow: hidden;
              border-radius: 20px;
              @include sp {
                width: 100%;
              }
              img {
                border-radius: 20px;
              }
            }
            &_txts {
              width: calc(50% - 20px);
              @include sp {
                width: 100%;
                margin-top: 15px;
              }
              .recruit-office-display-contents_ttl {
                font-size: 1.8rem;
                font-weight: bold;
                letter-spacing: $letter-space16;
                @include sp {
                  font-size: 1.6rem;
                }
              }
              .recruit-office-display-contents_txt {
                margin-top: 10px;
                font-size: 1.4rem;
                letter-spacing: $letter-space20;
                line-height: 2.5rem;
                @include sp {
                  margin-top: 5px;
                  font-size: 1.3rem;
                }
              }
            }
          }
          cursor: pointer;
          &:hover {
            .recruit-office_inner_other-content_wrap_top_imgtxt {
              img {
                transform: $hover-scale_12;
                transition: $img-hover-transition;
              }
            }
            .recruit-office-display-contents_ttl {
              color: $c-accent;
              transition: $hover-transition_300;
            }
          }
          .js-modal-contents {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100000;
            &_wrap {
              max-width: 800px;
              width: 80%;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 100001;
              transform: translate(-50%,-50%);
              @include sp {
                width: 100%;
                padding: 0 20px;
              }
              &_back {
                display: flex;
                justify-content: flex-end;
                &_box {
                  width: 35px;
                  height: 35px;
                  display: block;
                  position: relative;
                  cursor: pointer;
                  &:hover {
                    transform: $hover-scale_11;
                    transition: $hover-transition_300;
                  }
                  span {
                    width: 100%;
                    height: 2px;
                    display: block;
                    background: #fff;
                    position: absolute;
                    left: 0;
                    &:nth-of-type(1) {
                      top: 5px;
                      transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                      top: 5px;
                      transform: rotate(-45deg);
                    }
                  }
                }
              }
              &_img {
                cursor: auto;
                width: 100%;
              }
            }
            .office-bg {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100000;
              background: #000;
              opacity: 0.5;
              cursor: pointer;
            }
          }
        }
        &_list {
          margin-top: 60px;
          &_item {
            cursor: pointer;
            &:hover {
              .recruit-office-display-contents_img {
                img {
                  transform: $hover-scale_12;
                  transition: $img-hover-transition;
                }
              }
              .recruit-office-display-contents_ttl {
                color: $c-accent;
                transition: $hover-transition_300;
              }
            }
            .js-modal-contents {
              width: 100vw;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 100000;
              &_wrap {
                max-width: 800px;
                width: 80%;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 100001;
                transform: translate(-50%,-50%);
                @include sp {
                  width: 100%;
                  padding: 0 20px;
                }
                &_back {
                  display: flex;
                  justify-content: flex-end;
                  &_box {
                    width: 35px;
                    height: 35px;
                    display: block;
                    position: relative;
                    cursor: pointer;
                    &:hover {
                      transform: $hover-scale_11;
                      transition: $hover-transition_300;
                    }
                    span {
                      width: 100%;
                      height: 2px;
                      display: block;
                      background: #fff;
                      position: absolute;
                      left: 0;
                      &:nth-of-type(1) {
                        top: 5px;
                        transform: rotate(45deg);
                      }
                      &:nth-of-type(2) {
                        top: 5px;
                        transform: rotate(-45deg);
                      }
                    }
                  }
                }
                &_img {
                  cursor: auto;
                  width: 100%;
                }
              }
              .office-bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100000;
                background: #000;
                opacity: 0.5;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}