@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

/**
* テキストコンポーネント
*****************************************************/

// 通常テキスト
.c-nomal_txt {
  font-size: 1.6rem;
  letter-spacing: $letter-space20;
  line-height: 3rem;
  @include sp {
    font-size: 1.5rem;
  }
}

// 英数字テキスト
.c-txt-en {
  font-family: $over-pass;
}

// 注意書きテキスト
.c-notes {
  color: $c-sub;
  font-size: 1.2rem;
  letter-spacing: $letter-space20;
  line-height: 2.5rem;
  @include sp {
    font-size: 1.1rem;
  }
  &_txt {
    margin-left: 10px;
  }
}

// キャプション
.c-caption {
  margin-top: 15px;
  font-size: 1.4rem;
  text-align: center;
  display: inline-block;
  @include pc-sm {
    font-size: 1.3rem;
  }
  @include sp {
    font-size: 1.3rem;
  }
}