@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

/**
* レイアウトモジュール
*****************************************************/

.c-lower-img-flex {
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include sp {
    width: 100%;
    flex-direction: column;
  }
  &_img {
    width: calc(100% / 2 - 20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include sp {
      width: 100%;
    }
    &:nth-of-type(2) {
      @include sp {
        margin-top: 40px;
      }
    }
    img {
      max-width: 100%;
      width: auto;
    }
  }
}

// 2,3カラム画像タイトルテキスト
.mod-cul-img-ttl-txt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include sp {
    flex-direction: column;
  }
  &_item {
    &.cul2 {
      width: calc(100% / 2 - 20px);
      @include sp {
        width: 100%;
        margin-top: 30px;
      }
    }
    &.cul3 {
      width: calc(100% / 3 - 40px);
      @include sp {
        width: 100%;
      }
      &:nth-child(n+4) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &_img {
      overflow: hidden;
      border-radius: 20px;
      @include sp {
        border-radius: 16px;
      }
    }
    &_ttl {
      margin-top: 20px;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 3rem;
      letter-spacing: $letter-space16;
      @include sp {
        margin-top: 15px;
        font-size: 1.6rem;
      }

    }
    &_txt {
      margin-top: 10px;
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: $letter-space20;
      color: #64686c;
      @include sp {
        margin-top: 5px;
        font-size: 1.3rem;
      }
    }
  }
}

// 2,3カラム画像タイトル
.mod-cul-img-ttl {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  &.cul2 {
    justify-content: flex-start;
    @include sp {
      justify-content: space-between;
    }
    .mod-cul-img-ttl_item {
      &:not(:first-child) {
        margin-left: 40px;
        @include sp {
          margin-left: 0;
        }
      }
    }
  }
  @include sp {
    flex-direction: column;
  }
  &.sp-cul2 {
    @include sp {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &_item {
    &.cul2 {
      width: calc(100% / 2 - 40px);
      @include sp {
        width: 100%;
        margin-top: 30px;
      }
      &:nth-child(n+3) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &.cul3 {
      width: calc(100% / 3 - 40px);
      @include sp {
        width: 100%;
      }
      &:nth-child(n+4) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &.cu3-sp_cul2 {
      width: calc(100% / 3 - 40px);
      @include sp {
        width: calc(100% / 2 - 10px);
      }
      &:nth-child(n+4) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
      &:nth-child(n+3) {
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &_img {
      overflow: hidden;
      border-radius: 18px;
    }
    &_ttl {
      margin-top: 20px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 3rem;
      letter-spacing: $letter-space16;
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      @include sp {
        margin-top: 15px;
        font-size: 1.4rem;
      }
    }
  }
}

// 2,3カラム画像タイトル(リンクあり)
.mod-cul-img-ttl-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  &.sp-cul1 {
    @include sp {
      flex-direction: column;
    }
  }
  &_item {
    &.cul2 {
      width: calc(100% / 2 - 40px);
      @include sp {
        width: 100%;
        margin-top: 30px;
      }
      &:nth-child(n+3) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &.cul3 {
      width: calc(100% / 3 - 40px);
      @include sp {
        width: 100%;
      }
      &:nth-child(n+4) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 30px;
        }
      }
    }
    &.sp-cul2 {
      width: calc(100% / 2 - 20px);
      &:nth-child(n+3) {
        margin-top: 60px;
        @include sp {
          margin-top: 30px;
        }
      }
      @include sp {
        width: calc(100% / 2 - 10px);
      }
    }
    &_link {
      &_img {
        overflow: hidden;
        border-radius: 20px;
        @include sp {
          border-radius: 16px;
        }
      }
      &_ttl {
        margin-top: 20px;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 3rem;
        letter-spacing: $letter-space16;
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
        @include sp {
          margin-top: 20px;
          font-size: 1.6rem;
        }
      }
    }
  }
}