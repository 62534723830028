@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.service-mv {
  height: 80vh;
  padding-left: 40px;
  position: relative;
  @include sp {
    height: inherit;
    padding-left: 20px;
  }
  &_ttl {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    @include sp {
      margin-top: 100px;
      padding: 0;
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
  &_mvimg {
    width: calc(100% - 40px);
    max-width: 88%;
    height: 80vh;
    padding-left: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include sp {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
      position: inherit;
      max-width: inherit;
      height: inherit;
    }
    img {
      width: inherit;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 60px;
      object-fit: cover;
      @include sp {
        height: auto;
        border-radius: 20px 0 0 20px;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }
}

.service-content {
  margin-top: 70px;
  @include sp {
    margin-top: 50px;
  }
  &_product {
    width: 100%;
    &.purified {
      margin-top: 100px;
    }
    &_img {
      width: 100%;
      height: 400px;
      @include sp {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &_detail {
      padding: 0 40px;
      position: relative;
      top: -90px;
      margin-bottom: -90px;
      @include sp {
        margin-top: 30px;
        padding: 0 20px;
        top: 0;
        margin-bottom: 0;
      }
      &_inner {
        padding: 40px 80px 0;
        background: #fff;
        border-radius: 25px;
        @include sp {
          padding: 0;
          border-radius: 0;
        }
        &_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include sp {
            align-items: flex-start;
          }
          &_ttls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include sp {
              flex-direction: column;
              justify-content: inherit;
              align-items: flex-start;
            }
            &_img {
              width: 52px;
              @include sp {
                width: 44px;
              }
            }
            &_txt {
              margin-left: 20px;
              font-size: 3.8rem;
              font-weight: bold;
              letter-spacing: $letter-space13;
              @include sp {
                margin-left: 0;
                margin-top: 20px;
                font-size: 2.8rem;
              }
            }
          }
        }
        &_mid {
          margin-top: 40px;
          display: flex;
          @include sp {
            flex-direction: column-reverse;
          }
          &_main-pd {
            width: 230px;
            padding: 30px 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            box-shadow: $box-shadow;
            border-radius: 10px;
            position: relative;
            flex-shrink: 0;
            @include sp {
              width: 100%;
              padding: 30px 70px;
              margin-top: 30px;
            }
            &:hover {
              transform: $hover-scale_11;
              transition: $hover-transition_300;
            }
            &_arrow {
              width: 9px;
              position: absolute;
              bottom: 10px;
              right: 10px;
              transform: rotate(-45deg);
            }
          }
          &_txts {
            margin-left: 40px;
            @include sp {
              margin-left: 0;
            }
            &_ttl {
              font-size: 2.4rem;
              font-weight: bold;
              letter-spacing: $letter-space13;
              line-height: 3.5rem;
              @include pc-sm {
                font-size: 2rem;
              }
              @include sp {
                font-size: 2rem;
                line-height: 3rem;
              }
            }
            &_txt {
              margin-top: 30px;
              @include pc-sm {
                font-size: 1.5rem;
              }
              @include sp {
                margin-top: 15px;
              }
            }
          }
        }
        &_list {
          margin-top: 50px;
          display: flex;
          @include sp {
            margin-top: 20px;
            flex-wrap: wrap;
          }
          &_item {
            width: calc(100% / 4 - 20px);
            @include sp {
              width: calc(100% / 2 - 10px);
            }
            &:not(:first-child) {
              margin-left: 40px;
              @include sp {
                margin-left: 0;
              }
            }
            &:nth-child(n+3) {
              @include sp {
                margin-top: 20px;
              }
            }
            &:nth-child(2n) {
              @include sp {
                margin-left: 20px;
              }
            }
            &_link {
              height: 92px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #fff;
              box-shadow: $box-shadow;
              border-radius: 10px;
              position: relative;
              @include sp {
                height: 64px;
              }
              &:hover {
                transform: $hover-scale_11;
                transition: $hover-transition_300;
              }
              &_img {
                width: 56%;
                margin: 0 auto;
              }
              &_arrow {
                width: 9px;
                transform: rotate(-45deg);
                position: absolute;
                bottom: 10px;
                right: 10px;
              }
            }
          }
        }
      }
    }
  }
  &_business {
    margin-top: -300px;
    position: relative;
    background: $c-bg;
    top: 300px;
    @include sp {
      margin-top: -230px;
    }
    &_inner {
      padding: 100px 40px 320px;
      display: flex;
      justify-content: space-between;
      @include sp {
        padding: 60px 20px 320px;
        flex-direction: column-reverse;
      }
      &_detail {
        width: 50%;
        padding-right: 35px;
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-right: 0;
        }
        &_ttl {
          font-size: 3.2rem;
          font-weight: bold;
          letter-spacing: $letter-space13;
          @include sp {
            font-size: 2.4rem;
          }
        }
        &_txt {
          margin-top: 30px;
        }
        &_link {
          width: 230px;
          height: 92px;
          margin-top: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-radius: 8px;
          box-shadow: $box-shadow;
          position: relative;
          @include sp {
            width: 160px;
            height: 64px;
          }
          &:hover {
            transform: $hover-scale_11;
            transition: $hover-transition_300;
          }
          &_img {
            width: 160px;
            margin: 0 auto;
            @include sp {
              width: 110px;
            }
          }
          &_arrow {
            width: 9px;
            transform: rotate(-45deg);
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
      }
      &_img {
        width: 50%;
        padding-left: 35px;
        @include sp {
          width: 100%;
          padding-left: 0;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
}