@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

/**
* タイトルコンポーネント
*****************************************************/

// セクションタイトル
.c-section_ttl {
  &_sub {
    color: $c-accent;
    font-size: 1.7rem;
    letter-spacing: $letter-space13;
    font-weight: bold;
    @include sp {
      font-size: 1.5rem;
    }
  }
  &_h2 {
    margin-top: 25px;
    font-family: $over-pass;
    font-size: 6.2rem;
    letter-spacing: $letter-space13;
    @include sp {
      margin-top: 10px;
      font-size: 3.6rem;
    }
  }
}

// 日本語タイトル（英語小）
.c-section_ttl_ja {
  &_sub {
    color: $c-accent;
    font-size: 1.7rem;
    font-family: $over-pass;
    letter-spacing: $letter-space13;
    font-weight: bold;
    @include sp {
      font-size: 1.5rem;
    }
  }
  &_h2 {
    margin-top: 20px;
    font-size: 3rem;
    letter-spacing: $letter-space13;
    font-weight: bold;
    @include sp {
      margin-top: 10px;
      font-size: 2.4rem;
    }
  }
}

// 下層h3
.c-h3_ttl {
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid #d4d6d9;
  display: flex;
  justify-content: flex-start;
  position: relative;
  &.imgflex {
    padding: 30px 0;
    align-items: center;
  }
  @include sp {
    padding: 20px 0;
  }
  &::before {
    content: '';
    display: block;
    width: 45px;
    height: 2px;
    background: $c-accent;
    position: absolute;
    top: -1px;
    left: 0;
  }
  &_txt {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: $letter-space13;
    @include sp {
      font-size: 2.2rem;
    }
  }
}