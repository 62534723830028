@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

// mv
.t-mv {
  height: 100vh;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 800px;
    height: 706px;
    background: url(/assets/img/top/common/img_fluid_type01.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -160px;
    left: -80px;
    @include sp {
      width: 200px;
      height: 175px;
      top: -60px;
      left: -40px;
    }
  }
  &_inner {
    height: 100%;
    padding-left: 40px;
    position: relative;
    &_slider {
      width: 100%;
      height: calc(100vh - 30px);
      position: absolute;
      top: 0;
      right: 0;
      &_list {
        width: 100%;
        max-width: 85vw;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        @include pc-md {
          max-width: 1180px;
        }
        @include sp {
          width: calc(100% - 60px);
        }
        &_item {
          &_img {
            width: 100%;
            height: 100%;
            border-radius: 0 0 0 60px;
            overflow: hidden;
            position: relative;
            @include sp {
              padding-left: 60px;
            }
            img {
              width: inherit;
              height: 100%;
              object-fit: cover;
              border-radius: 0 0 0 60px;
              position: absolute;
              top: 0;
              right: 0;
              @include pc-md {
                width: 78vw;
              }
              @include sp {
                width: 100%;
                
              }
            }
          }
        }
      }
      .swiper-pagination {
        padding-left: 60px;
        display: flex;
        @include pc-md {
          padding-left: 40px;
        }
        @include sp {
          flex-direction: column;
          padding-left: 15px;
        }
        &-bullet {
          position: relative;
          width: 30px;
          height: 30px;
          background: #fff;
          border-radius: 50%;
          text-align: center;
          overflow: hidden;
          z-index: 1;
          display: block;
          opacity: 1;
          @include sp {
            margin: 2px 0 !important;
          }
          &-active {
            background: $c-accent;
            .swiper-pagination-bullet-inner {
              &::after {
                background: $c-accent;
              }
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: -15px;
              width: 30px;
              height: 30px;
              background: #e6eaed;
              transform-origin: right 15px;
              z-index: 2;
              animation: rotate-circle-left 4s linear forwards;
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 0px;
              left: 15px;
              width: 30px;
              height: 30px;
              background: #e6eaed;
              transform-origin: left 15px;
              z-index: 3;
              animation: rotate-circle-right 4s linear forwards;
            }
            @keyframes rotate-circle-right {
              0%   {
                  transform: rotate(0deg);
                  background: #e6eaed;
              }
              50%  {
                  transform: rotate(180deg);
                  background: #e6eaed;
              }
              50.01% {
                  transform: rotate(360deg);
                  background: $c-accent;
              }
              100% {
                  transform: rotate(360deg);
                  background: $c-accent;
              }
          }
          @keyframes rotate-circle-left {
              0%   { transform: rotate(0deg); }
              50%  { transform: rotate(0deg); }
              100% { transform: rotate(180deg); }
          }
          }
          &-inner {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 26px;
            height: 26px;
            background: #fff;
            border-radius: 50%;
            z-index: 4;
            &::after {
              position: absolute;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #e6eaed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 5;
            }
          }
        }
      }
    }
    &_lead {
      position: absolute;
      top: 50%;
      left: 60px;
      z-index: 2;
      transform: translateY(-50%);
      @include sp {
        left: 20px;
      }
      &_txt {
        font-size: 5.6rem;
        font-family: $mincho;
        letter-spacing: $letter-space13;
        line-height: 8rem;
        color: #21445b;
        @include sp {
          font-size: 2.8rem;
          line-height: 5rem;
        }
      }
    }
    &_scroll {
      width: 100%;
      padding: 0 40px;
      position: absolute;
      bottom: 70px;
      left: 0;
      z-index: 6;
      @include sp {
        padding: 0 20px;
        bottom: 60px;
      }
      &_inner {
        display: flex;
        justify-content: flex-end;
        &_wrap {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: 1px solid #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          transition: $hover-transition_300;
          @include sp {
            width: 70px;
            height: 70px;
          }
          &:hover {
            transform: $hover-scale_11;
            transition: $hover-transition_300;
          }
          &_txt {
            font-size: 1.4rem;
            font-family: $over-pass;
            color: #fff;
            @include sp {
              font-size: 1.2rem;
            }
          }
          &_img {
            width: 25px;
            margin-top: 10px;
            transform: rotate(90deg);
            @include sp {
              width: 17px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .fluid-img_type2 {
    bottom: 70px;
  }
}

// 重要なお知らせ
.important-notices {
  margin-top: 50px;
  padding: 0 40px;
  @include sp {
    padding: 0 20px;
  }
  &_inner {
    padding: 50px;
    background: #fff;
    box-shadow: $box-shadow;
    border-radius: 20px;
    display: flex;
    align-items: center;
    @include sp {
      padding: 30px 20px;
      flex-direction: column;
      align-items: flex-start;
    }
    &_ttl {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &_img {
        width: 24px;
        @include sp {
          width: 20px;
        }
      }
      &_txt {
        margin-left: 10px;
        font-size: 2rem;
        font-weight: bold;
        color: $c-notes;
        letter-spacing: $letter-space13;
        @include sp {
          font-size: 1.8rem;
        }
      }
    }
    &_detail {
      margin-left: 50px;
      @include sp {
        margin-left: 0;
        margin-top: 20px;
      }
      &_list {
        &_item {
          &_link {
            display: flex;
            align-items: center;
            @include sp {
              flex-direction: column;
              align-items: flex-start;
            }
            &_date {
              width: 90px;
              color: $c-sub;
              font-size: 1.4rem;
              font-family: $over-pass;
              flex-shrink: 0;
            }
            &_txt {
              margin-left: 25px;
              font-size: 1.6rem;
              letter-spacing: $letter-space20;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              @include sp {
                margin-left: 0;
                margin-top: 10px;
                font-size: 1.5rem;
              }
            }
            &:hover {
              .important-notices_inner_detail_list_item_link_txt {
                color: #5ea3e2;
                transition: $hover-transition_200;
              }
            }
          }
          &:not(:first-child) {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

// ニュース・キャンペーン
.t-news {
  margin-top: 110px;
  padding: 0 40px;
  @include sp {
    margin-top: 60px;
    padding: 0 20px;
  }
  position: relative;
  .fluid-img_type1 {
    @include sp {
      top: -350px;
    }
  }
  .fluid-img_type2 {
    top: -420px;
    @include sp {
      top: 0;
    }
  }
  &_inner {
    &_content {
      margin-top: 60px;
      @include sp {
        width: 100%;
        margin: 30px 0 0;
      }
      &_list {
        display: flex;
        &_item {
          width: calc(100% / 3 - 27px);
          @include sp {
            width: 100%;
          }
          &:not(:first-child) {
            margin-left: 40px;
            @include sp {
              margin-left: 0;
            }
          }
          &_link {
            .news-item {
              &_img {
                overflow: hidden;
                border-radius: 20px;
                border: 1px solid #d4d6d9;
              }
              &_date {
                margin-top: 20px;
                color: $c-sub;
                font-family: $over-pass;
                font-size: 1.4rem;
              }
              &_ttl {
                margin-top: 10px;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 2.8rem;
                letter-spacing: $letter-space16;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @include sp {
                  margin-top: 8px;
                }
              }
            }
            &:hover {
              .news-item {
                &_img {
                  border: 1px solid #d4d6d9;
                  img {
                    transition: $img-hover-transition;
                    transform: $hover-scale_12;
                  }
                }
                &_ttl {
                  color: $c-accent;
                  transition: $hover-transition_200;
                  
                }
              }
            }
          }
          .news-tag {
            margin-top: 20px;
            &_list {
              display: flex;
              &_item {
                padding: 4px 15px;
                  display: block;
                  border-radius: 12px;
                  border: 1px solid $c-accent;
                  color: $c-accent;
                &:not(:first-child) {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    &_view-more {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      @include sp {
        justify-content: flex-end;
      }
    }
  }
}

// メッセージ
.t-message {
  margin-top: 110px;
  position: relative;
  @include sp {
    margin-top: 60px;
  }
  .fluid-img_type1 {
    top: -400px;
    @include sp {
      top: -550px;
    }
  }
  &_inner {
    &_content {
      height: 700px;
      position: relative;
      @include sp {
        height: 700px;
      }
      &_img {
        height: 700px;
        width: auto;
        padding-right: 40px;
        position: absolute;
        top: 0;
        left: 0;
        @include sp {
          height: inherit;
          padding-right: 20px;
        }
        img {
          height: 100%;
          max-width: 1240px;
          border-radius: 0 60px 0 0;
          object-fit: cover;
          @include sp {
            height: auto;
          }
        }
      }
      &_detail {
        width: 100%;
        max-width: 55%;
        margin-left: 40px;
        background: rgba(255,255,255,0.7);
        border-radius: 60px 0 0 0;
        position: absolute;
        bottom: 0;
        right: 0;
        @include sp {
          max-width: inherit;
          margin-left: 20px;
        }
        &_inner {
          padding: 80px 60px 40px 80px;
          @include pc-md {
            padding: 60px 40px 40px 80px;
          }
          @include sp {
            padding: 50px 30px;
          }
          &_subttl {
            margin-top: 40px;
            font-size: 3.2rem;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: $letter-space16;
            @include sp {
              margin-top: 30px;
              font-size: 2.2rem;
            }
          }
          &_txt {
            max-width: 650px;
            margin-top: 20px;
            font-size: 1.6rem;
            line-height: 3rem;
            letter-spacing: $letter-space20;
            @include sp {
              font-size: 1.5rem;
            }
          }
          &_view-more {
            margin-top: 40px;
            display: flex;
            justify-content: flex-start;
            @include sp {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

// サービス
.t-service {
  margin-top: 480px;
  padding: 0 40px;
  padding-bottom: 100px;
  background: $c-bg;
  position: relative;
  @include sp {
    margin-top: 30px;
    padding: 0 20px;
  }
  .fluid-img_type2 {
    top: -750px;
    @include sp {
      top: -820px;
    }
  }
  &_inner {
    position: relative;
    bottom: 370px;
    margin-bottom: -370px;
    @include sp {
      padding: 60px 0;
      position: inherit;
      bottom: 0;
      margin-bottom: 0;
    }
    &_subttl {
      margin-top: 50px;
      font-size: 1.5rem;
      @include sp {
        margin-top: 30px;
        line-height: 2;
      }
    }
    &_content {
      margin-top: 50px;
      display: flex;
      @include sp {
        flex-direction: column;
      }
      &_natural {
        width: 57%;
        border-right: 1px solid $c-bg;
        @include sp {
          width: 100%;
        }
        &_link {
          &:hover {
            .t-service_inner_content_natural_link_imgs {
              &_mainimg {
                img {
                  transform: $hover-scale_12;
                  transition: $img-hover-transition;
                }
              }
            }
          }
          &_imgs {
            position: relative;
            &_mainimg {
              border-radius: 24px 0 0 0;
              overflow: hidden;
              position: relative;
              top: 0;
              left: 0;
              @include sp {
                border-radius: 24px 24px 0 0;
              }
            }
            &_layer {
              width: 100%;
              height: 225px;
              background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.6));
              display: flex;
              align-items: flex-end;
              position: absolute;
              bottom: 0;
              left: 0;
              @include sp {
                height: 130px;
              }
              &_inner {
                padding: 30px 40px;
                display: flex;
                align-items: center;
                @include sp {
                  padding: 20px;
                  flex-direction: column;
                  align-items: flex-start;
                }
                &_logo {
                  width: 52px;
                  @include sp {
                    width: 32px;
                  }
                }
                &_txt {
                  margin-left: 20px;
                  color: #fff;
                  font-size: 3rem;
                  font-weight: bold;
                  letter-spacing: $letter-space13;
                  @include sp {
                    margin-left: 0;
                    margin-top: 10px;
                    font-size: 2.2rem;
                  }
                }
              }
            }
          }
          &_detail {
            height: 240px;
            padding: 0 40px;
            background: #fff;
            position: relative;
            border-radius: 0 0 0 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include pc-sm {
              padding: 0 20px;
            }
            @include sp {
              padding: 0 20px;
              border-radius: 0 0 24px 24px;
            }
            &_txt {
              font-size: 1.4rem;
              line-height: 2.8rem;
              letter-spacing: $letter-space20;
              color: #64686c;
              @include sp {
                font-size: 1.3rem;
              }
            }
            &_brand {
              margin-top: 30px;
              display: flex;
              align-items: center;
              @include sp {
                margin-top: 20px;
              }
              &_ttl {
                background: $c-accent;
                padding: 6px 15px;
                border-radius: 24px;
                &_txt {
                  font-size: 1.2rem;
                  color: #fff;
                  font-weight: bold;
                  @include sp {
                    font-size: 1.1rem;
                  }
                }
              }
              &_img {
                width: 160px;
                margin-left: 20px;
                @include pc-sm {
                  width: 120px;
                }
                @include sp {
                  width: 135px;
                }
              }
            }
            &_arrow {
              width: 15px;
              position: absolute;
              bottom: 20px;
              right: 20px;
              transform: rotate(-45deg);
              @include sp {
                bottom: 15px;
                right: 15px;
              }
            }
          }
        }
      }
      &_purified {
        width: 43.42%;;
        @include sp {
          width: 100%;
          margin-top: 30px;
        }
        &_link {
          &:hover {
            .t-service_inner_content_purified_link_imgs {
              &_mainimg {
                img {
                  transform: $hover-scale_12;
                  transition: $img-hover-transition;
                }
              }
            }
          }
          &_imgs {
            position: relative;
            &_mainimg {
              border-radius: 0 24px 0 0;
              overflow: hidden;
              position: relative;
              top: 0;
              left: 0;
              @include sp {
                border-radius: 24px 24px 0 0;
              }
            }
            &_layer {
              width: 100%;
              height: 225px;
              background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.6));
              display: flex;
              align-items: flex-end;
              position: absolute;
              bottom: 0;
              left: 0;
              @include sp {
                height: 130px;
              }
              &_inner {
                padding: 30px 40px;
                display: flex;
                align-items: center;
                @include sp {
                  padding: 20px;
                  flex-direction: column;
                  align-items: flex-start;
                }
                &_logo {
                  width: 52px;
                  @include sp {
                    width: 32px;
                  }
                }
                &_txt {
                  margin-left: 20px;
                  color: #fff;
                  font-size: 3rem;
                  font-weight: bold;
                  letter-spacing: $letter-space13;
                  @include sp {
                    margin-left: 0;
                    margin-top: 10px;
                    font-size: 2.2rem;
                  }
                }
              }
            }
          }
          &_detail {
            height: 240px;
            background: #fff;
            padding: 0 40px;
            position: relative;
            border-radius: 0 0 24px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include pc-sm {
              padding: 0 20px;
            }
            @include sp {
              height: 220px;
              padding: 0 20px;
              border-radius: 0 0 24px 24px;
            }
            &_txt {
              font-size: 1.4rem;
              line-height: 2.8rem;
              letter-spacing: $letter-space20;
              color: #64686c;
              @include sp {
                font-size: 1.3rem;
              }
            }
            &_brand {
              margin-top: 30px;
              display: flex;
              align-items: center;
              @include sp {
                margin-top: 20px;
              }
              &_ttl {
                background: $c-accent;
                padding: 6px 15px;
                border-radius: 24px;
                &_txt {
                  font-size: 1.2rem;
                  color: #fff;
                  font-weight: bold;
                  @include sp {
                    font-size: 1.1rem;
                  }
                }
              }
              &_img {
                width: 160px;
                margin-left: 20px;
                @include pc-sm {
                  width: 90px;
                }
                @include sp {
                  width: 135px;
                }
              }
            }
            &_arrow {
              width: 15px;
              position: absolute;
              bottom: 20px;
              right: 20px;
              transform: rotate(-45deg);
              @include sp {
                bottom: 15px;
                right: 15px;
              }
            }
          }
        }
      }
    }
    // コンテンツ事業
    .t-business {
      margin-top: 40px;
      border-radius: 16px;
      @include sp {
        margin-top: 30px;
      }
      &_inner {
        &_link {
          display: flex;
          position: relative;
          @include sp {
            flex-direction: column;
          }
          &:hover {
            .t-business_inner_link_img {
              img {
                transform: $hover-scale_12;
                transition: $img-hover-transition;
              }
            }
            .t-business_inner_link_detail {
              &_ttl {
                color: $c-accent;
                transition: $hover-transition_200;
              }
            }
          }
          &_img {
            max-width: 340px;
            border-radius: 16px 0 0 16px;
            overflow: hidden;
            flex-shrink: 0;
            @include sp {
              width: 100%;
              max-width: inherit;
              border-radius: 16px 16px 0 0;
            }
          }
          &_detail {
            padding: 0 40px;
            position: relative;
            background: #fff;
            border-radius: 0 16px 16px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include pc-sm {
              padding: 0 20px;
            }
            @include sp {
              padding: 25px 20px 40px;
              border-radius: 0 0 16px 16px;
            }
            &_ttl {
              font-size: 2.4rem;
              font-weight: bold;
              letter-spacing: $letter-space13;
              @include sp {
                font-size: 1.8rem;
              }
            }
            &_txt {
              margin-top: 20px;
              font-size: 1.4rem;
              line-height: 2.5rem;
              letter-spacing: $letter-space20;
              color: #64686c;
              @include sp {
                font-size: 1.3rem;
              }
            }
          }
          &_arrow {
            position: absolute;
            bottom: 20px;
            right: 20px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    &_view-more {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      @include sp {
        justify-content: flex-end;
      }
    }
  }
}

// 企業情報
.t-corporate {
  margin-top: 100px;
  padding: 0 40px;
  position: relative;
  @include sp {
    margin-top: 60px;
    padding: 0 20px;
  }
  .fluid-img_type1 {
    top: -500px;
    @include sp {
      top: -100px;
    }
  }
  .fluid-img_type2 {
    @include sp {
      top: 200px;
    }
  }
  &_inner {
    &_content {
      display: flex;
      align-items: center;
      @include sp {
        flex-direction: column-reverse;
      }
      &_detail {
        width: 50%;
        padding-right: 80px;
        @include pc-sm {
          padding-right: 50px;
        }
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-right: 0;
        }
        &_txt {
          margin-top: 40px;
          font-size: 1.6rem;
          line-height: 3rem;
          letter-spacing: $letter-space20;
          @include sp {
            margin-top: 30px;
            font-size: 1.5rem;
          }
        }
        &_view-more {
          margin-top: 45px;
          @include sp {
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        max-width: 600px;
        @include sp {
          width: 100%;
        }
      }
    }
  }
}

// SDGsへの取り組み
.t-sdgs {
  margin-top: 100px;
  padding: 0 40px;
  position: relative;
  @include sp {
    margin-top: 60px;
    padding: 0 20px;
  }
  .fluid-img_type1 {
    @include sp {
      top: -250px;
    }
  }
  .fluid-img_type2 {
    top: -280px;
    @include sp {
      top: 120px;
    }
  }
  &_inner {
    &_content {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      @include sp {
        flex-direction: column-reverse;
      }
      &_detail {
        width: 50%;
        padding-left: 80px;
        @include pc-sm {
          padding-left: 50px;
        }
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-left: 0;
        }
        &_txt {
          margin-top: 40px;
          font-size: 1.6rem;
          line-height: 3rem;
          letter-spacing: $letter-space20;
          @include sp {
            margin-top: 30px;
            font-size: 1.5rem;
          }
        }
        &_view-more {
          margin-top: 45px;
          @include sp {
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        max-width: 600px;
        @include sp {
          width: 100%;
        }
      }
    }
  }
}

// 採用情報
.t-recruit {
  height: fit-content;
  margin-top: 100px;
  position: relative;
  @include sp {
    margin-top: 60px;
  }
  .fluid-img_type1 {
    top: -50px;
    @include sp {
      top: -350px;
    }
  }
  &_inner {
    &_ttls {
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &_subttl {
        margin-top: 50px;
        font-size: 1.5rem;
        line-height: 3rem;
        letter-spacing: $letter-space20;
        @include sp {
          margin-top: 20px;
        }
      }
    }
    &_content {
      margin-top: 60px;
      &_list {
        display: flex;
        @include sp {
          flex-direction: column;
        }
        &_item {
          width: calc(100% / 3);
          @include sp {
            width: 100%;
          }
          &_link {
            width: 100%;
            height: 455px;
            display: block;
            position: relative;
            @include sp {
              height: auto;
            }
            &:hover {
              .t-recruit_inner_content_list_item_link_img {
                img {
                  transform: $hover-scale_12;
                  transition: $img-hover-transition;
                }
              }
            }
            &_img {
              height: 455px;
              position: relative;
              top: 0;
              left: 0;
              overflow: hidden;
              @include sp {
                height: auto;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include sp {
                  height: auto;
                }
              }
            }
            &_layer {
              width: 100%;
              height: 222px;
              background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7));
              display: flex;
              align-items: flex-end;
              position: absolute;
              bottom: 0;
              left: 0;
              @include sp {
                height: 145px;
              }
              &_inner {
                padding: 40px 0 40px 45px;
                @include sp {
                  padding: 25px 20px;
                }
                &_txt {
                  font-size: 2.8rem;
                  font-weight: bold;
                  color: #fff;
                  letter-spacing: $letter-space13;
                  @include pc-md {
                    font-size: 2.4rem;
                  }
                  @include sp {
                    font-size: 2rem;
                  }

                }
                &_arrow {
                  width: 15px;
                  position: absolute;
                  bottom: 20px;
                  right: 20px;
                }
              }
            }
          }
        }
      }
    }
    &_view-more {
      padding: 0 40px;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      @include sp {
        padding: 0 20px;
        margin-top: 40px;
        justify-content: flex-end;
      }
    }
  }
  .fluid-img_type2 {
    &.bottom {
      @include sp {
        bottom: -250px;
      }
    }
  }
}