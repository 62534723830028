@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

// ニュース・キャンペーン
.news-mv {
  height: 80vh;
  padding-left: 40px;
  position: relative;
  @include sp {
    height: inherit;
    
    padding-left: 20px;
  }
  &_ttl {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    @include sp {
      margin-top: 120px;
      padding: 0;
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
  &_mvimg {
    width: calc(100% - 40px);
    max-width: 88%;
    height: 80vh;
    padding-left: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include sp {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
      position: inherit;
      max-width: inherit;
      height: inherit;
    }
    img {
      width: inherit;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 60px;
      object-fit: cover;
      @include sp {
        height: auto;
        border-radius: 20px 0 0 20px;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }
  &_tocm {
    padding: 0 40px;
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 2;
    @include sp {
      padding: 0 20px;
      bottom: 20px;
    }
    &_wrap {
      display: flex;
      justify-content: flex-end;
      @include pc-md {
        padding: 0;
      }
      &_link {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &_border {
          width: 155px;
          height: 100%;
          background: $c-accent;
          border-radius: 30px;
        }
        &:hover {
          .news-mv_tocm_wrap_link_border {
            transform: $hover-scale_11;
            transition: $hover-transition_200;
          }
        }
        &_txt {
          padding: 0 30px;
          font-size: 1.6rem;
          font-weight: bold;
          color: #fff;
          letter-spacing: $letter-space13;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          @include sp {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.news-content {
  margin-top: 60px;

  @include sp {
    margin-top: 40px;
    padding: 0;
  }
  &_inner {
    &_search {
      &_year {
        padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
        display: flex;
        align-items: center;
        @include sp {
          flex-direction: column;
          align-items: flex-start;
        }
        &_ttl {
          font-size: 1.6rem;
          font-weight: bold;
          letter-spacing: $letter-space13;
          pointer-events: none;
          @include sp {
            font-size: 1.5rem;
            flex-shrink: 0;
          }
        }
        &_choose {
          margin-left: 30px;
          position: relative;
          @include sp {
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
          }
          &_sec {
            width: 200px;
            padding: 15px 20px;
            font-size: 1.6rem;
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            cursor: pointer;
            @include sp {
              width: 100%;
            }
          }
          &_img {
            width: 7px;
            position: absolute;
            top: 36%;
            right: 25px;
            transform: rotate(90deg) translateY(-50%);
          }
        }
      }
      &_category {
        margin-top: 32.5px;
        display: flex;
        align-items: flex-start;
        position: relative;
        @include sp {
          margin-top: 25px;
          flex-direction: column;
          align-items: flex-start;
        }
        &::after {
          content: '';
          width: 70px;
          height: 40px;
          display: block;
          background: linear-gradient(90deg,rgba(255,255,255,0.3),rgba(255,255,255,1));
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 0;
          pointer-events: none;
        }
        &_ttl {
          padding: 12.5px 0 0 40px;
          flex-shrink: 0;
          @include sp {
            padding: 0 20px;
          }
          font-size: 1.6rem;
          font-weight: bold;
          letter-spacing: $letter-space13;
          @include sp {
            font-size: 1.5rem;
          }
        }
        &_wrap {
          padding-right: 40px;
          @include sp {
            width: 100%;
            padding-left: 20px;
            margin-top: 20px;
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
          }
          &::-webkit-scrollbar {
            @include sp {
              display: none;
            }
          }
          &_list {
            margin-left: 22.5px;
            display: flex;
            flex-wrap: wrap;
            @include sp {
              padding-bottom: 5px;
              margin-left: 0;
              flex-wrap: nowrap;
            }
            &_item {
              padding: 6px 25px;
              margin: 7.5px;
              border-radius: 20px;
              border: 1px solid $c-accent;
              color: $c-accent;
              font-size: 1.4rem;
              display: flex;
              align-items: center;
              cursor: pointer;
              @include sp {
                margin: 0;
                padding: 6px 18px;
                font-size: 1.3rem;
                white-space: nowrap;
                position: relative;
              }
              &:hover {
                background: $c-accent;
                transition: $hover-transition_200;
                color: #fff;
                @include sp {
                  background: inherit;
                  transition: inherit;
                  color: $c-accent;
                }
              }
              &.active {
                background: $c-accent;
                font-size: 1.3rem;
                color: #fff;
                position: relative;
                z-index: 1;
              }
              &:not(:first-child) {
                @include sp {
                  margin-left: 10px;
                }
              }
              &:last-child {
                position: relative;
                z-index: 1;
              }
              &:last-child::after {
                @include sp {
                  position: absolute;
                  content: '';
                  top: 0;
                  left: 100%;
                  height: 1px;
                  width: 20px;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    &_newsdisplay {
      margin-top: 100px;
      &.is-hide {
        display: none;
      }
      @include sp {
        margin-top: 60px;
        padding: 0 10px;
      }
      .ttl-wrap {
        padding: 0 40px;
        @include sp {
          padding: 0 10px;
        }
      }
      &_list {
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        @include sp {
          padding: 0;
        }
        &_item {
          width: calc(100% / 3 - 40px);
          margin: 30px 20px;
          &.is-hide {
            display: none;
          }
          &.is-hidden {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin: 0;
          }
          @include sp {
            width: calc(100% / 2 - 20px);
            margin: 30px 10px 0;
          }
          &_link {
            display: block;
            .news-item {
              &_img {
                overflow: hidden;
                border-radius: 20px;
                border: 1px solid #d4d6d9;
              }
              &_date {
                margin-top: 20px;
                color: $c-sub;
                font-family: $over-pass;
                font-size: 1.4rem;
              }
              &_ttl {
                margin-top: 10px;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 2.8rem;
                letter-spacing: $letter-space16;
                @include sp {
                  margin-top: 8px;
                  font-size: 1.6rem;
                }
              }
            }
            &:hover {
              .news-item {
                &_img {
                  border: 1px solid #d4d6d9;
                  img {
                    transition: $img-hover-transition;
                    transform: $hover-scale_12;
                  }
                }
                &_ttl {
                  color: $c-accent;
                  transition: $hover-transition_200;
                  
                }
              }
            }
          }
          .news-tag {
            margin-top: 20px;
            margin-left: -5px;
            @include sp {
              margin-left: -5px;
            }
            &_list {
              display: flex;
              flex-wrap: wrap;
              &_item {
                padding: 4px 15px;
                display: block;
                border-radius: 12px;
                border: 1px solid $c-accent;
                color: $c-accent;
                margin: 7.5px 5px;
                @include sp {
                  margin: 5px;
                }
              }
            }
          }
        }
      }
    }
    &_news-more {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      &.is-hide {
        display: none;
      }
      &_wrap {
        padding: 0 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include sp {
          padding: 0 20px;
        }
        &:hover {
          .news-content_inner_news-more_wrap_icon {
            transform: $hover-scale_11;
            transition: $hover-transition_200;
            span {
              transform: translateX(-50%) scale(0.9);
              transition: $hover-transition_200;
              &:nth-of-type(2) {
                transform: translateX(-50%) rotate(90deg) scale(0.9);
              }
            }
          }
        }
        &_txt {
          font-size: 1.8rem;
          letter-spacing: $letter-space13;
          @include sp {
            font-size: 1.6rem;
          }
        }
        &_icon {
          width: 60px;
          height: 60px;
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid $c-accent;
          border-radius: 50%;
          position: relative;
          @include sp {
            width: 48px;
            height: 48px;
            margin-left: 15px;
          }
          span {
            width: 40%;
            height: 2px;
            background: $c-accent;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            &:nth-of-type(2) {
              transform: translateX(-50%) rotate(90deg);
            }
          }
        }
      }
    }
  }
}

// ニュース・キャンペーン(詳細ページ)
.news-detail {
  margin-top: 150px;
  @include sp {
    margin-top: 120px;
  }
  &_inner {
    padding: 0 40px;
    margin-top: 70px;
    @include sp {
      margin-top: 40px;
      padding: 0 20px;
    }
    &_content {
      &_lead {
        &_date {
          font-size: 1.4rem;
          color: $c-sub;
          font-family: $over-pass;
          @include sp {
            font-size: 1.3rem;
          }
        }
        &_ttl {
          margin-top: 15px;
          font-size: 2.6rem;
          line-height: 4rem;
          font-weight: bold;
          letter-spacing: $letter-space16;
          @include sp {
            margin-top: 10px;
            font-size: 2rem;
            line-height: 3rem;
          }
        }
        .news-tag {
          margin-top: 20px;
          @include sp {
            margin-top: 15px;
          }
          &_list {
            display: flex;
            &_item {
              padding: 4px 15px;
              display: block;
              border-radius: 12px;
              border: 1px solid $c-accent;
              color: $c-accent;
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
        }
      }
      &_display {
        margin-top: 30px;
        padding: 40px 0 70px;
        border-top: 1px solid #d6d6d8;
        border-bottom: 1px solid #d6d6d8;
        @include sp {
          margin-top: 25px;
          padding: 30px 0 70px;
        }
        &_img {
          max-width: 900px;
          display: flex;
          justify-content: center;
          img {
            max-width: 100%;
            width: auto;
          }
        }
        img {
          margin: 0 auto;
          width: auto;
          display: block;
          @include sp {
            max-width: 100%;
            width: auto;
          }
        }
        p {
          margin-top: 30px;
          font-size: 1.6rem;
          font-weight: normal;
          letter-spacing: $letter-space20;
          line-height: 3rem;
          color:#383a3c;
          @include sp {
            font-size: 1.5rem;
          }
          & strong {
            font-size:1.4em;
            font-weight:bold;
          }
        }
        &_ttl {
          margin-top: 40px;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 3rem;
          letter-spacing: $letter-space20;
        }
      }
      &_prevnext {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_prev {
          display: flex;
          align-items: center;
          &:hover {
            .news-detail_inner_content_prevnext_prev_txt {
              color: $c-accent;
              transition: $hover-transition_200;
            }
          }
          &_img {
            width: 15px;
            transform: rotate(180deg);
          }
          &_txt {
            margin-left: 10px;
            @include nomal-txt
          }
        }
        &_next {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          &:hover {
            .news-detail_inner_content_prevnext_next_txt {
              color: $c-accent;
              transition: $hover-transition_200;
            }
          }
          &_img {
            width: 15px;
            margin-left: 10px;
          }
          &_txt {
            @include nomal-txt
          }
        }
      }
      &_button {
        margin-top: 70px;
        @include sp {
          margin-top: 40px;
        }
        &_link {
          &_border {
            min-width: 260px;
            @include sp {
              min-width: 230px;
            }
          }
          &_txt {
            white-space: nowrap;
          }
        }
      }
    }
  }
  > .news-detail_inner_content_prevnext {
    max-width: 980px;
    min-width: 768px;
    width: 100%;
    margin: 30px auto 0;
    padding: 0 40px;
    @include sp {
      max-width: inherit;
      min-width: inherit;
      width: 100%;
      padding: 0 20px;
    }
  }
}


// TVCM
.tvcm {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_latest-cm {
      &_display {
        display: flex;
        cursor: pointer;
        @include sp {
          flex-direction: column;
        }
        &:hover {
          .tvcm_inner_latest-cm_display_explanation {
            &_ttl {
              color: $c-accent;
              transition: $hover-transition_300;
            }
          }
          .tvcm_inner_latest-cm_display_img {
            &_sumb {
              transform: $hover-scale_12;
              transition: $img-hover-transition;
            }
          }
        }
        &_img {
          width: 100%;
          max-width: 560px;
          position: relative;
          overflow: hidden;
          border-radius: 20px;
          &_start {
            width: 60px;
            height: 60px;
            border: 1px solid #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            &_img {
              width: 14px;
            }
          }
          &_sumb {
            border-radius: 20px;
          }
        }
        &_explanation {
          margin-left: 40px;
          @include sp {
            margin-left: 0;
            margin-top: 15px;
          }
          &_ttl {
            font-size: 2.2rem;
            font-weight: bold;
            line-height: 4rem;
            @include sp {
              font-size: 1.8rem;
              text-align: center;
              line-height: 3rem;
            }
          }
        }
      }
      .js-modal-contents {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        &_wrap {
          max-width: 800px;
          width: 80%;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 100001;
          transform: translate(-50%,-50%);
          @include sp {
            width: 100%;
            padding: 0 20px;
          }
          &_back {
            display: flex;
            justify-content: flex-end;
            &_box {
              width: 35px;
              height: 35px;
              display: block;
              position: relative;
              cursor: pointer;
              &:hover {
                transform: $hover-scale_11;
                transition: $hover-transition_300;
              }
              span {
                width: 100%;
                height: 2px;
                display: block;
                background: #fff;
                position: absolute;
                left: 0;
                &:nth-of-type(1) {
                  top: 5px;
                  transform: rotate(45deg);
                }
                &:nth-of-type(2) {
                  top: 5px;
                  transform: rotate(-45deg);
                }
              }
            }
          }
          &_movie {
            cursor: auto;
            width: 100%;
            aspect-ratio: 16 / 9;
            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
        .bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 100000;
          background: #000;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
    &_list {
      margin-top: 100px;
      @include sp {
        margin-top: 60px;
      }
      &_item {
        &_display {
          cursor: pointer;
          &_img {
            position: relative;
            &_start {
              width: 60px;
              height: 60px;
              border: 1px solid #fff;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
              @include sp {
                width: 30px;
                height: 30px;
              }
              &_img {
                width: 14px;
                @include sp {
                  width: 7px;
                }
              }
            }
          }
          &_ttl {
            text-align: center;
            @include sp {
              margin-top: 15px;
              text-align: left;
            }
          }
          &:hover {
            .tvcm_inner_list_item_display_ttl {
              color: $c-accent;
              transition: $hover-transition_300;
            }
            .tvcm_inner_list_item_display_img{
              &_sumb {
                transform: $hover-scale_12;
                transition: $img-hover-transition;
              }
            }
          }
        }
        .js-modal-contents {
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 100000;
          &_wrap {
            max-width: 800px;
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 100001;
            transform: translate(-50%,-50%);
            @include sp {
              width: 100%;
              padding: 0 20px;
            }
            &_back {
              display: flex;
              justify-content: flex-end;
              &_box {
                width: 35px;
                height: 35px;
                display: block;
                position: relative;
                cursor: pointer;
                &:hover {
                  transform: $hover-scale_11;
                  transition: $hover-transition_300;
                }
                span {
                  width: 100%;
                  height: 2px;
                  display: block;
                  background: #fff;
                  position: absolute;
                  left: 0;
                  &:nth-of-type(1) {
                    top: 5px;
                    transform: rotate(45deg);
                  }
                  &:nth-of-type(2) {
                    top: 5px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
            &_movie {
              cursor: auto;
              width: 100%;
              aspect-ratio: 16 / 9;
              iframe {
                width: 100%;
                height: 100%;
              }
            }
          }
          .bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100000;
            background: #000;
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }
    }
    &_button {
      margin-top: 70px;
      @include sp {
        margin-top: 40px;
      }
      &_link {
        min-width: 280px;
        &_border {
          min-width: 280px;
        }
        .c-back_button_link_txt {
          white-space: nowrap;
        }
      }
    }
  }
}