@charset "UTF-8";
@use "mixin" as *;
@use "color" as *;
@use "variable" as *;

/*================================
	共通設定
=================================*/
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif; font-weight: 500;
  font-feature-settings : "palt"; 
  letter-spacing : 0.04em;
  color: #383a3c;
}

.pc-only {
  display: block !important;
  @include sp {
    display: none !important;
  }
}

.sp-only {
  display: none !important;
  @include sp {
    display: block !important;
  }
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  margin-left: 0;
}

.page-width {
  max-width: 1280px;
  min-width: 944px;
  width: 100%;
  margin: 0 auto;
  // padding: 0 40px;
  @include pc-smd {
    max-width: 1280px;
    min-width: 680px;
  }
  @include sp {
    max-width: inherit;
    min-width: inherit;
    width: 100%;
    // padding: 0 20px;
  }
}
.contents-width {
  max-width: 1280px;
  min-width: 1124px;
  width: 100%;
  @include sp {
    max-width: inherit;
    min-width: inherit;
    width: 100%;
  }
}

.lower-width {
  max-width: 980px;
  min-width: 768px;
  width: 100%;
  margin: 0 auto;
  @include sp {
    max-width: inherit;
    min-width: inherit;
    width: 100%;
  }
}

// パンクズ
.breadcrumb {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  @include sp {
    display: none;
  }
  &_nav {
      padding: 0 40px;
    a {
      font-size: 1.2rem;
      color: $c-sub;
      &:not(:first-child) {
        margin-left: 10px;
        &::before {
          content: 'ー';
          display: inline-block;
          margin-right: 10px;
        }
      }
      &:hover {
        color: $c-accent;
        transition: $hover-transition_200;
      }
      &.current {
        pointer-events: none;
      }
    }
  }
}

// 下層ページMV
.mod-lower_mv {
  @include lower-mv
}

// マージントップ
// pc
@for $i from 0 through 150 {
  .mt#{$i} {
    margin-top: #{$i}px;
  }
}

// sp
@for $i from 0 through 150 {
  .sp-mt#{$i} {
    margin-top: #{$i}px;
  }
}

// マージンレフト
@for $i from 0 to 150 {
  .ml#{$i} {
    margin-left: #{$i}px;
  }
}