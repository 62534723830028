@charset "UTF-8";
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/mixin" as *;
.footer {
  margin-top: 300px;
  padding: 0 40px;
  background: $c-bgfooter;
  @include sp {
    margin-top: 250px;
    padding: 0 20px;
  }
  &_inner {
    position: relative;
    &_conductor {
      width: 100%;
      background: #fff;
      border-radius: 20px;
      position: absolute;
      top: -180px;
      left: 0px;
      box-shadow: $box-shadow;
      &_content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include sp {
          flex-direction: column;
        }
        &_product {
          width: 50%;
          @include sp {
            width: 100%;
          }
          &_link {
            padding: 60px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            @include sp {
              padding: 35px 0;
            }
            &::after {
              content: '';
              display: block;
              width: 1px;
              height: 200px;
              background: #d4d6d9;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              @include sp {
                width: 86%;
                height: 1px;
                top: inherit;
                right: 0;
                bottom: 0;
                left: 50%;
                transform: translateY(0) translateX(-50%);
              }
            }
            &:hover {
              .footer_inner_conductor_content_product_link_wrap {
                &_txt {
                  color: $c-accent;
                  transition: $hover-transition_200;
                }
              }
            }
            &_wrap {
              padding: 0 20px;
              &_logo {
                width: 268px;
                margin: 0 auto;
              }
              &_txt {
                margin-top: 35px;
                text-align: center;
                font-size: 1.7rem;
                letter-spacing: $letter-space20;
                line-height: 3rem;
                font-weight: bold;
                @include sp {
                  margin-top: 25px;
                  font-size: 1.6rem;
                }
              }
              &_arrow {
                width: 13px;
                margin: 25px auto 0;
                transform: rotate(-45deg);
                @include sp {
                  margin-top: 20px;
                }
              }
            }
          }
        }
        &_sns {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include sp {
            width: 100%;
          }
          &_wrap {
            padding: 60px 20px;
            @include sp {
              padding: 35px 20px;
            }
            &_list {
              display: flex;
              align-items: center;
              justify-content: center;
              &_item {
                &:not(:first-child) {
                  margin-left: 25px;
                  @include sp {
                    margin-left: 15px;
                  }
                }
                &_link {
                  width: 48px;
                  height: 48px;
                  display: block;
                  @include pc-sm {
                    width: 42px;
                    height: 42px;
                  }
                  @include sp {
                    width: 36px;
                    height: 36px;
                  }
                }
              }
            }
            &_link {
              &:hover {
                .footer_inner_conductor_content_sns_wrap_link_txt {
                  color: $c-accent;
                  transition: $hover-transition_200;
                }
              }
              &_txt {
                margin-top: 45px;
                text-align: center;
                font-size: 1.7rem;
                letter-spacing: $letter-space20;
                line-height: 3rem;
                font-weight: bold;
                @include sp {
                  margin-top: 30px;
                  font-size: 1.6rem;
                }
              }
              &_arrow {
                width: 15px;
                margin: 20px auto 0;
              }
            }
          }
        }
      }
    }
    &_navs {
      padding-top: 215px;
      @include sp {
        padding: 400px 10px 0;
      }
      &_logo {
        width: 272px;
        margin: 0 auto;
        @include sp {
          width: 219px;
        }
      }
      &_list {
        margin-top: 65px;
        padding-bottom: 80px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #4d697c;
        @include sp {
          flex-direction: column;
          padding-bottom: 0;
        }
        &_item {
          @include sp {
            padding: 24px 0;
            border-top: 1px solid #4d697c;
          }
          &_link {
            display: flex;
            align-items: center;
            @include sp {
              justify-content: space-between;
            }
            &:hover {
              .footer_inner_navs_list_item_link_txt {
                opacity: $opacity-035;
              }
            }
            &_txt {
              color: #fff;
              font-size: 1.6rem;
              font-weight: bold;
              letter-spacing: $letter-space13;
              @include pc-smd {
                font-size: 1.4rem;
              }
              @include pc-sm {
                font-size: 1.2rem;
              }
            }
            &_img {
              width: 15px;
              margin-left: 8px;
              &.deg45 {
                transform: rotate(-45deg);
              }
              @include pc-sm {
                width: 13px;
              }
              @include sp {
                width: 15px;
                margin-left: 0;
              }
            }
          }
          &:not(:first-child) {
            margin-left: 70px;
            @include pc-smd {
              margin-left: 40px;
            }
            @include pc-sm {
              margin-left: 20px;
            }
            @include sp {
              margin-left: 0;
            }
          }
          .footer_lower_list {
            margin-top: 30px;
            &_item {
              &_link {
                color: #fff;
                font-size: 1.4rem;
                letter-spacing: $letter-space13;
                @include pc-sm {
                  font-size: 1.2rem;
                }
                &:hover {
                  opacity: $opacity-035;
                }
                &.ablank {
                  display: flex;
                  align-items: center;
                  .footer_lower_list_item_link_img{
                    width: 15px;
                    margin-left: 8px;
                    @include pc-sm {
                      width: 13px;
                    }
                    @include sp {
                      width: 13px;
                    }
                    &.deg45 {
                      transform: rotate(-45deg);
                    }
                  }
                }
              }
              &:not(:first-child) {
                margin-top: 20px;
              }
            }
          }
        }
      }
      &_otherlist {
        margin-top: 45px;
        display: flex;
        justify-content: center;
        @include sp {
          margin-top: 30px;
          flex-direction: column;
        }
        &_item {
          &_link {
            font-size: 1.3rem;
            color: #fff;
            letter-spacing: $letter-space13;
            @include pc-sm {
              font-size: 1.2rem;
            }
            @include sp {
              font-size: 1.2rem;
            }
            &:hover {
              opacity: $opacity-035;
            }
          }
          &:not(:first-child) {
            margin-left: 35px;
            @include sp {
              margin-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }
    &_copyright {
      margin-top: 30px;
      padding-bottom: 60px;
      display: flex;
      justify-content: center;
      @include sp {
        margin-top: 40px;
        padding: 0 10px 60px;
        justify-content: flex-start;
      }
      &_txt {
        color: #4d697c;
        font-size: 1.1rem;
      }
    }
  }
}