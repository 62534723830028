@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

// 企業情報TOP
.corporate-mv {
  height: 80vh;
  padding-left: 40px;
  position: relative;
  @include sp {
    height: inherit;
    padding-left: 20px;
  }
  &_ttl {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    @include sp {
      margin-top: 100px;
      padding: 0;
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
  &_mvimg {
    width: calc(100% - 40px);
    max-width: 88%;
    height: 80vh;
    padding-left: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include sp {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
      position: inherit;
      max-width: inherit;
      height: inherit;
    }
    img {
      width: inherit;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 60px;
      object-fit: cover;
      @include sp {
        height: auto;
        border-radius: 20px 0 0 20px;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }
}

.corporate-content {
  &_txtimg {
    margin-top: 70px;
    @include sp {
      margin-top: 50px;
    }
    &_inner {
      width: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp {
        padding: 0 20px;
        flex-direction: column-reverse;
      }
      &_detail {
        width: 50%;
        padding-right: 50px;
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-right: 0;
        }
        &_view-more {
          margin-top: 40px;
          @include sp {
            margin-top: 25px;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        padding-left: 20px;
        @include sp {
          width: 100%;
          padding-left: 0;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
  &_imgtxt {
    margin-top: 70px;
    @include sp {
      margin-top: 40px;
    }
    &_inner {
      width: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      @include sp {
        flex-direction: column-reverse;
      }
      @include sp {
        padding: 0 20px;
      }
      &_detail {
        width: 50%;
        padding-left: 50px;
        @include sp {
          width: 100%;
          margin-top: 30px;
          padding-left: 0;
        }
        &_view-more {
          margin-top: 40px;
          @include sp {
            margin-top: 25px;
            justify-content: flex-end;
          }
        }
      }
      &_img {
        width: 50%;
        padding-right: 20px;
        @include sp {
          width: 100%;
          padding-right: 0;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
}

// 会社概要
.corporate-profile {
  margin-top: 70px;
  @include sp {
    margin-top: 40px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_img {
      img {
        border-radius: 20px;
      }
    }
    &_content {
      margin-top: 60px;
      @include sp {
        margin-top: 30px;
      }
      &_list {
        &_item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .corporate-profile_ttl {
            width: calc(20% - 15px);
            padding: 25px 0 35px;
            border-top: 1px solid $c-accent;
            @include sp {
              width: calc(25% - 10px);
              padding: 20px 0 30px;
            }
            &_txt {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              font-weight: bold;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
            }
          }
          .corporate-profile_detail {
            width: calc(80% - 15px);
            margin-left: 30px;
            padding: 25px 0 35px;
            border-top: 1px solid #d4d6d9;
            @include sp {
              width: calc(75% - 10px);
              margin-left: 15px;
              padding: 20px 0 30px;
            }
            &_ttl {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              font-weight: bold;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
            }
            &_txt {
              font-size: 1.6rem;
              letter-spacing: $letter-space16;
              line-height: 2.5rem;
              @include sp {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

// ご挨拶
.corporate-greetings {
  margin-top: 70px;
  @include sp {
    margin-top: 50px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_img {
      width: 100%;
      img {
        border-radius: 20px;
      }
    }
    &_ttl {
      margin-top: 70px;
      font-size: 3rem;
      font-weight: bold;
      line-height: 5rem;
      @include sp {
        margin-top: 40px;
        font-size: 2rem;
        line-height: 3rem;
      }
    }
    &_names {
      margin-top: 30px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      @include sp {
        margin-top: 20px;
      }
      &_representative {
        margin-top: 10px;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: $letter-space20;
        @include sp {
          margin-top: 5px;
          font-size: 1.7rem;
        }
      }
    }
  }
}

// 今後の展望
.corporate-future {
  margin-top: 70px;
  @include sp {
    margin-top: 50px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_mvimg {
      img {
        border-radius: 20px;
        @include sp {
          border-radius: 15px;
        }
      }
    }
    &_imgnotes {
      margin-top: 30px;
      font-size: 1.2rem;
      color: #868c91;
      text-align: right;
    }
    &_ttl {
      margin-top: 70px;
      font-size: 3rem;
      font-weight: bold;
      line-height: 4.5rem;
      text-align: center;
      @include sp {
        margin-top: 40px;
        font-size: 2.2rem;
        line-height: 3.8rem;
        text-align: left;
      }
    }
    &_txt {
      margin-top: 40px;
      @include sp {
        margin-top: 30px;
      }
    }
    &_imgttl {
      margin-top: 60px;
      font-size: 2.2rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        margin-top: 40px;
        font-size: 1.7rem;
      }
    }
    &_worldimg  {
      margin-top: 50px;
      @include sp {
        margin-top: 30px;
      }
    }
    &_sec {
      margin-top: 70px;
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &:not(:first-child) {
        margin-top: 100px;
        @include sp {
          margin-top: 70px;
        }
      }
      &_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row-reverse;
        @include sp {
          flex-direction: column;
        }
        &_img {
          width: calc(100% / 2 - 25px);
          @include sp {
            width: 100%;
          }
          img {
            border-radius: 20px;
            @include sp {
              border-radius: 15px;
            }
          }
        }
        &_detail {
          width: calc(100% / 2 - 25px);
          @include sp {
            width: 100%;
            margin-top: 30px;
          }
          &_notes {
            margin-top: 40px;
            font-size: 1.2rem;
            color: #868c91;
            @include sp {
              margin-top: 30px;
            }
            .notes-txt {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}