@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.message-mv {
  height: 80vh;
  padding-left: 40px;
  position: relative;
  @include sp {
    height: inherit;
    padding-left: 20px;
  }
  &_ttl {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    @include sp {
      margin-top: 100px;
      padding: 0;
      position: inherit;
      top: inherit;
      left: inherit;
      transform: inherit;
    }
  }
  &_mvimg {
    width: calc(100% - 40px);
    max-width: 88%;
    height: 80vh;
    padding-left: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @include sp {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
      position: inherit;
      max-width: inherit;
      height: inherit;
    }
    img {
      width: inherit;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 60px;
      object-fit: cover;
      @include sp {
        height: auto;
        border-radius: 20px 0 0 20px;
        position: inherit;
        top: inherit;
        right: inherit;
      }
    }
  }
}

.message-content {
  margin-top: 70px;
  @include sp {
    margin-top: 50px;
  }
  &_passion {
    &_inner {
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &_txt {
        margin-top: 40px;
        @include sp {
          margin-top: 30px;
        }
      }
      &_imgttl {
        margin-top: 70px;
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: $letter-space13;
        text-align: center;
        @include sp {
          margin-top: 40px;
          font-size: 1.7rem;
        }
      }
      &_img {
        margin-top: 30px;
      }
    }
  }
  &_way-system {
    margin-top: 100px;
    background: $c-bg;
    @include sp {
      margin-top: 60px;
    }
    &_inner {
      padding: 100px 40px 120px;
      @include sp {
        padding: 60px 20px 70px;
      }
      &_txt {
        margin-top: 40px;
        @include sp {
          margin-top: 30px;
        }
      }
      &_img {
        margin-top: 50px;
        @include sp {
          margin-top: 35px;
        }
      }
    }
  }
  &_sas {
    margin-top: 100px;
    @include sp {
      margin-top: 60px;
    }
    &_inner {
      padding: 0 40px;
      @include sp {
        padding: 0 20px;
      }
      &_list {
        margin-top: 40px;
        &_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include sp {
            flex-direction: column;
          }
          &_imgs {
            width: 50%;
            @include sp {
              width: 100%;
            }
            &_img {
              img {
                border-radius: 20px;
              }
            }
            &_notes {
              margin-top: 15px;
              font-size: 1.4rem;
              text-align: center;
              @include pc-sm {
                font-size: 1.3rem;
              }
              @include sp {
                font-size: 1.3rem;
              }
            }
          }
          &_detail {
            width: 50%;
            @include sp {
              width: 100%;
              margin-top: 30px;
            }
            &_num {
              font-size: 3.6rem;
              font-family: $over-pass;
              color: $c-accent;
              letter-spacing: $letter-space13;
              @include sp {
                font-size: 3.2rem;
              }
            }
            &_ttl {
              margin-top: 15px;
              font-size: 2.4rem;
              font-weight: bold;
              letter-spacing: $letter-space13;
              line-height: 4rem;
              @include sp {
                font-size: 2rem;
                line-height: 3rem;
              }
            }
            &_list {
              margin-top: 30px;
              &_item {
                .list_num {
                  color: $c-accent;
                }
                &:not(:first-child) {
                  margin-top: 10px;
                }
              }
            }
            &_txt {
              margin-top: 15px;
            }
          }
          &:not(:first-child) {
            margin-top: 70px;
            @include sp {
              margin-top: 50px;
            }
          }
          &:nth-child(even) {
            flex-direction: row-reverse;
            @include sp {
              flex-direction: column;
            }
            .message-content_sas_inner_list_item_imgs {
              padding-left: 20px;
              @include sp {
                padding-left: 0;
              }
            }
            .message-content_sas_inner_list_item_detail {
              padding-right: 35px;
              @include sp {
                padding-right: 0;
              }
              &_txt {
                margin-top: 30px;
              }
            }
          }
          &:nth-child(odd) {
            .message-content_sas_inner_list_item_imgs {
              padding-right: 20px;
              @include sp {
                padding-right: 0;
              }
            }
            .message-content_sas_inner_list_item_detail {
              padding-left: 45px;
              @include sp {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}