@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.page-body {
  margin-top: 60px;
  @include sp {
    margin-top: 30px;
  }
  .container {
    max-width: 980px;
    min-width: 768px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    @include pc-smd {
      max-width: 980px;
      min-width: 680px;
    }
    @include sp {
      max-width: inherit;
      min-width: inherit;
      width: 100%;
      // padding: 0 20px;
    }
    @include pc-md {
      padding: 0 40px;
    }
    @include pc-smd {
      max-width: 980px;
      min-width: 700px;
    }
    @include sp {
      max-width: inherit;
      min-width: inherit;
      width: 100%;
      padding: 0 20px;
    }
    p {
      margin: 1em 0 2em;
      font-size: 1.6rem;
      line-height: 2.25;
      letter-spacing: $letter-space20;
      @include sp {
        font-size: 1.4rem;
      }
    }
    h3 {
      margin: 60px 0 30px;
      font-size: 3rem;
      letter-spacing: $letter-space16;
      @include sp {
        margin: 30px 0 25px;
        font-size: 2rem;
      }
    }
    h4 {
      margin: 40px 0 20px;
      font-size: 2.2rem;
      font-weight: bold;
      @include sp {
        font-size: 1.5rem;
      }
    }
    a {
      color: #254a62;
      word-break: break-all;
      text-decoration: underline;
    }
    .ordered-list {
      ol {
        margin: 20px 0;
        padding: 0 0 0 1.8em;
        font-size: 1.6rem;
        line-height: 1.875;
        counter-reset: num 0;
        list-style: none;
        @include sp {
          font-size: 1.4rem;
        }
        li {
          position: relative;
          margin: 1em 0;
          &:first-child {
            margin-top: 0;
          }
          &::before {
            counter-increment: num 1;
            content: counter(num) ".";
            position: absolute;
            left: -1.8em;
            display: block;
            width: 1em;
            color: #21445b;
            font-weight: bold;
            text-align: center;
            counter-increment: num 1;
          }
        }
      }
    }
    .annotation {
      margin: 28px 0 30px;
      padding: 0;
      font-size: 1.2rem;
      line-height: 2.142857143;
    }
    .align-right {
      text-align: right;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
      margin: 40px 0;
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 10px;
      table {
        @include sp {
          white-space: normal;
        }
      }
    }
    .table-bordered,
    .table-horizontal-heading {
      th {
        text-align: center;
        border-top: 1px solid rgba(243, 241, 239, .5);
        &:not(:first-child) {
          border-left: 1px solid rgba(243, 241, 239, .5);
        }
      }
      td {
        background: #f3f1ef;
        vertical-align: top;
        border-top: 1px solid rgba(196, 175, 144, .5);
        font-size: 1.4rem;
        line-height: 2.142857143;
        &:not(:first-child) {
          border-left: 1px solid rgba(196, 175, 144, .5);
        }
        @include sp {
          font-size: 1.3rem;
        }
      }
    }
    .table-bordered {
      th {
        background: #c4af90;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1.875;
        white-space: nowrap;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }
    .table-bordered th,
    .table-bordered td {
      padding: 16px 30px;
    }
    .table-overflow table {
      @include sp {
        width: 640px;
      }
    }
    .table-overflow::-webkit-scrollbar {
      height: 6px;
      border-radius: 3px;
    }
    .table-overflow::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #c4af90;
    }
    .table-overflow::-webkit-scrollbar-track {
      border-radius: 3px;
      background: #eaeaea;
    }
  }
}