@charset "UTF-8";

// @use foundation scss
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/mixin" as *;
@use "../../foundation/font" as *;

// @use compornent scss
@use "../component/button" as *;
@use "../component/layout" as *;
@use "../component/ttl" as *;
@use "../component/txt" as *;

.notice {
  margin-top: 60px;
  @include sp {
    margin-top: 30px;
  }
  &_inner {
    padding: 0 40px;
    @include sp {
      padding: 0 20px;
    }
    &_adtxt {
      margin-top: 10px;
    }
    &_button {
      margin-top: 30px;
      &_link {
        min-width: 280px;
        &_border {
          min-width: 300px;
        }
      }
    }
    &_settlementttl {
      margin-top: 100px;
      @include sp {
        margin-top: 60px;
      }
    }
    &_settlementtxt {
      margin-top: 10px;
    }
    &_list {
      margin-top: 35px;
      &_item {
        border-bottom: 1px solid #d4d6d9;
        &_link {
          display: block;
          padding: 25px 0;
          &:hover {
            .notice-contents {
              &_detail {
                &_txt {
                  color: $c-accent;
                  transition: $hover-transition_200;
                }
              }
            }
          }
          .notice-contents {
            display: flex;
            align-items: center;
            @include sp {
              flex-direction: column;
              align-items: flex-start;
            }
            &_date {
              width: 80px;
              &_txt {
                font-size: 1.4rem;
                font-family: $over-pass;
                color: $c-sub;
              }
            }
            &_detail {
              margin-left: 40px;
              @include sp {
                margin-left: 0;
                margin-top: 10px;
              }
              &_txt {
                font-size: 1.6rem;
                &::after {
                  content: '';
                  display: inline-block;
                  width: 28px;
                  height: 12px;
                  margin-left: 8px;
                  background: url(/assets/img/common/img_common_pdf.png);
                  background-size: contain;
                }
                @include sp {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}